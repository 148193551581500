import { useState } from "react";
import { UserContext } from "../_contexts/UserContext";
import { UserTableGenericType } from "../_models/user";

const user: UserTableGenericType = Object.keys(sessionStorage).
    reduce((acc: any, key: any) => (key ? { ...acc, ...{ [key]: sessionStorage.getItem(key) } } : { ...acc }), {})

export const UserProvider = ({ children }: any) => {
    const [data, setData] = useState<any>(user || null);
    const sharedState = {
        data,
        set: setData,
        logout: () => {
            setData(null);
            localStorage.clear();
            sessionStorage.clear();
        }
    };
    return <UserContext.Provider value={sharedState}>
        {children}
    </UserContext.Provider>
}