import './maintenance.css';
import { getUserCompany } from '../_services/permission.service';

let dashboardLink = "";

const Maintenance = () => {    
    dashboardLink = "https://lookerstudio.google.com/embed/reporting/dd92a0ea-9c91-489d-8f1e-6edce5bf4ee2/page/uAcRE"
    const company = getUserCompany();
    if (company === 'skyports'){
        dashboardLink = "https://lookerstudio.google.com/embed/reporting/cbf24fe9-2358-4f9a-a2c5-995d4b933879/page/OMqkD"
    }
    
    return (
        <div className="dashboard-container">
            <iframe width="100%" 
            height="100%" 
            src= { dashboardLink }
            allowFullScreen></iframe>
        </div>
    )
};

export default Maintenance;