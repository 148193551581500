import { createContext, useContext } from "react";

type ClientContextState = {
    data: any;
    set: (data: any) => void;
    logout: () => void;
  };

export const UserContext = createContext<ClientContextState>({} as ClientContextState);

export const useUser = () => useContext(UserContext);