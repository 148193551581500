import Box from '@mui/material/Box';
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { QRCodeCanvas } from 'qrcode.react';
import { useState } from 'react';
import JiraService from '../_services/jira.service';
import { BackdropComponent } from './backdrop';
import TextField from '@mui/material/TextField/TextField';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ValidatorComponent = ({ open, handleModalClose, handleModalOnSubmit, secretCode }: any) => {
    // Get Context
    let [otp, setOtp] = useState('');

    const onSubmit = async (event: any) => {
        event.preventDefault();
        handleModalOnSubmit(otp);
    }

    function handleOtpChange(value: string) {
        const re = /^[0-9]{1,6}$/g;
        if (value === '' || re.test(value)) {
            setOtp(value)
        }
    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {secretCode ? <QRCodeCanvas
                        value={secretCode}
                        size={128}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                    /> : <></>}
                    <Box pt={3} onSubmit={onSubmit}
                        component="form"
                        noValidate
                        autoComplete="off">
                        <Box sx={{ width: '100%' }}>
                            <TextField
                                autoFocus={true}
                                id="otp-input-required"
                                required
                                label="OTP"
                                name="otp"
                                InputLabelProps={{ shrink: true }}
                                placeholder='OTP'
                                value={otp}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOtpChange(event.target.value)}
                            />
                        </Box>
                        <Box mt={2}>
                            <Button variant="outlined" onClick={() => { handleModalClose('delete'); }}>
                                Cancel
                            </Button>
                            <Button variant="contained" className="float-right" type="submit">
                                Submit
                            </Button>
                        </Box>

                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ValidatorComponent;
