import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import { SideNavType } from '../../_models/side-nav';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Link, NavLink } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import MenuList from '@mui/material/MenuList';
import '../../navigation/navbar.css';
const drawerWidth = 240;

let activeStyle = {
    textDecoration: "underline",
    marginLeft: '0.5em'
  };

const SideNav = ({ items }: { items: SideNavType }) => {
    return (<Drawer
        variant="permanent"
        sx={{
            zIndex: 999,
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
    >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
            <MenuList>
                {items?.map(x =>
                (<Box style={{padding: '0.5em'}} key={x.label}>
                    {x.icon}
                    <NavLink to={x.href} className='userNavLink ' style={({isActive}) => ({
                        ...(isActive ? {...activeStyle, width: '100%'} : null),
                    })} end>
                        {x.label}
                    </NavLink>
                </Box>
                )) ?? <></>}
            </MenuList>
        </Box>
        <Divider />
    </Drawer>);
}

export default SideNav;