import React, { useState } from 'react';
import './data-table.css';
import Table from 'antd/lib/table';

const PrintDataTable = React.forwardRef(({columns, data}: any, ref: any) => 
<Table ref={ref}
    rowKey={columns.key}
      columns={columns} 
      dataSource={data} 
      pagination={false}
    />
);

const DataTable = ({columns, data, loading}: any) => {
  // State
  // State of page size for pagination
  const [pageSize, setPageSize] = useState<number>(10);

  /**
   * Handle pagination size changes - to set the pageSize
   * @param current current page
   * @param size current size of the page
   */
  const handlePaginationSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

return <Table 
  loading={loading}
  columns={columns} 
  dataSource={data} 
  scroll={{ x: true }}

  pagination={{ responsive: true, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, showSizeChanger: true, total: data.length, pageSize, onShowSizeChange: handlePaginationSizeChange }}

/>
};


export {DataTable, PrintDataTable};