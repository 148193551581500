import { createContext, useContext } from "react";

type ClientContextState = {
    permission: any;
    setPermission: (data: any) => void;
    reset: () => void;
  };

export const PermissionContext = createContext<ClientContextState>({} as ClientContextState);

export const usePermission = () => useContext(PermissionContext);