import moment from 'moment';
import { format, parse, isValid } from 'date-fns';
import { SelectedDateRangeType } from '../_models/report';
import { CognitoExceptionType } from '../_models/cognito';
import { TOASTBOX } from '../_models/toastbox';
import * as fs from 'file-saver';
import { Xlsx } from 'exceljs';
export namespace UtilService {


  // Sorting Mechanism
  /**
   * @param {string} dateA - a date, represented in string format
   * @param {string} dateB - a date, represented in string format
   */
  const dateSort = (dateA: string, dateB: string) => moment(dateA).diff(moment(dateB));

  const timeSort = (a: number, b: number) => {
    const distantFuture = new Date(8640000000000000).getTime();
    let dateA = a ? a : distantFuture
    let dateB = b ? b : distantFuture
    return dateA - dateB
  };

  /**
   *
   * @param {number|string} a
   * @param {number|string} b
   */
  const defaultSort = (a: string | number, b: string | number) => {
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  };

  function textSort(a: string, b: string) {
    return (a || "|||").toUpperCase().localeCompare((b || "|||").toUpperCase())
  }

  export const Sorter = {
    defaultSort,
    dateSort,
    textSort,
    timeSort
  };

  // Time/Date conversion

  export function convertMSToTimeFormat(milliSec: number): string {
    const padTo2Digits = (num: number) => {
      return num ? Number(num.toString().padStart(2, '0')) : 0;
    }
    let seconds = padTo2Digits(Math.floor(milliSec / 1000));
    let minutes = padTo2Digits(Math.floor(seconds / 60));
    let hours = padTo2Digits(Math.floor(minutes / 60));

    seconds = seconds % 60;
    minutes = minutes % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  }

  export function convertSecsToHMSFormat(durationInSec: number) {
    const padTo2Digits = (num: number) => {
      return num ? Number(num.toString().padStart(2, '0')) : 0;
    }
    let seconds = padTo2Digits(Math.floor(durationInSec % 3600 % 60));
    let minutes = padTo2Digits(Math.floor(durationInSec % 3600 / 60));
    let hours = padTo2Digits(Math.floor(durationInSec / 3600));
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  export function getDateRange(): SelectedDateRangeType {
    const currentDate = moment();
    // const currentDateInDays = currentDate.date();
    // const currentWeekDaysNum = currentDate.day();
    // const dateDay = currentDateInDays - currentWeekDaysNum + (currentWeekDaysNum === 0 ? -6 : 1)
    return {
      startDate: currentDate.toDate(),
      endDate: currentDate.toDate(),
    };
  }

  export function convertTimeMSToDate(ms: number) {
    return moment(moment().startOf('day').valueOf() + Number(ms));
  }

  /**
     * To determine and transform flightTime if it has no hours to show min and sec only.
     * @param flightTime flight time as sent by jira
     * @returns transformed flight time
     */
  export function transformFlightTime(flightTime: string): string {
    return flightTime[0] !== '0' ? flightTime : flightTime.substring(3);
  }

  /**
   * To manipulate land/ground time's Date as Flight Date and Time as selected time due to JIRA accepting 
   * DateTime and not time format.
   * @param flightDate in Date Object. 
   * @param value selected land/ground time in Date Object
   * @returns a new Date object with the flight date and selected time.
   */
  export function transformLandGroundTime(flightDate: Date, value: Date) {
    if(!isValid(flightDate)){
      return new Date();
    }
    return parse(`${format(flightDate, 'dd/MM/yyyy')} ${format(new Date(value), 'HH:mm:ss')}`, 'dd/MM/yyyy HH:mm:ss', new Date());
  }


  export function diffArray(arr1: any[], arr2: any[]) {
    return [...diff(arr1, arr2), ...diff(arr2, arr1)];

    function diff(a: any[], b: any[]) {
      return a.filter(item => b.indexOf(item) === -1);
    }
  }

  export function addRowKeyToTableItems(items: Array<any>) {
    return items.map((ele: any) => {
      return { key: `${(Math.random() + 1)}`, ...ele }
    });
  }

  export function titleCase(str: string) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  export function generateErrorToastMessage(error: any) {
    let errorMsg = '';
    if (error?.response) {
      errorMsg = getErrorMessage(error.response.data.name);
    } else if (error?.message) {
      errorMsg = error.message;
    }
    return errorMsg;
  }

  function getErrorMessage(error: CognitoExceptionType) {
    const errorObj: { [key: CognitoExceptionType]: string } = {
      'NotAuthorizedException': 'Username or Password is wrong.',
      'UserNotFound': 'User does not exists.',
      'UsernameExistsException': 'Username already exist, please choose another username.',
      'PasswordResetRequiredException': 'Password Reset is required.',
      'CodeMismatchException': 'Code entered is not correct.',
      'ExpiredCodeException': 'Code has expired. Please generate a new one.'
    };
    return errorObj[error] ?? error;
  }

  export function errorToast(error: any, toastState: any): TOASTBOX {
    return { ...toastState, openCloseState: true, duration: 5000, message: generateErrorToastMessage(error), type: 'error' };
  }
}
