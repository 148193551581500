import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { MultiDropdownSelectKeyValue } from '../_models/dropdown';
import { Button } from 'antd';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const SELECT_SX = {color: 'grey !important'};

const SELECT_BUTTON_SX = {margin: '0 auto', width: 'fit-content', paddingBottom: '0.5em'};

interface IMultiSelect { disable?: boolean, item: MultiDropdownSelectKeyValue[], parentCallback: any, title: string, style: any, data: string[], forFilter: boolean }
/**
 * To update Item constants with the proper checked values according to data for edit mode and extract those checked's values.
 * @param data logs data for edit
 * @param item list of constants to set Checked as true based on data and extract the values of checked values
 * @returns array of values that are checked True in item.
 */
function getDropdownItem(data: any, item: any) {
  if (data?.length > 0) {
    data.forEach((x: any) => {
      let selectable = item.find((ele: any) => ele.key === x);
      if (selectable) selectable.checked = true;
    });
  }
  return item.flatMap((x: any) => x.checked ? x.value : []);
}

const disableOption = (options: MultiDropdownSelectKeyValue[], disable: boolean) => {
  options.forEach(y => {
    if (!y.checked) y.disabled = disable;
    if (y.checked && y.disabled) y.disabled = false;
  });
}

// meant for additional batteries in create logs
const checkBatteryOptions = (currentOption: string[], dropdownItem: MultiDropdownSelectKeyValue[]) => {
  if (currentOption?.length === 1) {
    disableOption(dropdownItem, true);
  } else {
    disableOption(dropdownItem, false);
  }
}

// Main Component
const MultipleSelect = ({ disable, item, parentCallback, title, style, data, forFilter }: IMultiSelect) => {
  const [dropdownItem, setDropdownItem] = React.useState<string[]>(getDropdownItem(data, item));
  const handleChange = (obj: { key: string | number, value: string, checked: boolean }) => {
    // check if the filter manipulate table columns or table data
    if (title.toLowerCase() === 'filter columns') {
      parentCallback.handleFilterColumns(obj.key, !obj.checked);
    } else {
      parentCallback.handleMultiSelectChange(obj.key, !obj.checked, title.toLowerCase(), obj.value);
    }
    
    const dropdownOption = item.flatMap(x => x.checked ? x.value : []);

    if (title.toLowerCase() === 'additional battery') checkBatteryOptions(dropdownOption, item);

    setDropdownItem(dropdownOption)
  };
  if (title.toLowerCase() === 'additional battery') checkBatteryOptions(dropdownItem, item);

  return (
    <FormControl key={`${title}_key_formControl`} sx={style}>
      <InputLabel shrink={true} id="multiple-checkbox-label">{title}</InputLabel>
      <Select
        sx={dropdownItem?.length > 0 ?  null : SELECT_SX}
        key={`${title}_key_select`}
        disabled={disable}
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        displayEmpty={true}
        multiple
        value={dropdownItem}
        input={<OutlinedInput label={title} notched={true} />}
        renderValue={() => renderTextHelper(item, title, forFilter)}
        MenuProps={MenuProps}
      >
        {/* this part handles the select/deselect all functionality */}
        <Box sx={SELECT_BUTTON_SX}> 
          <Button type="primary" onClick={((event: React.MouseEvent) => {
            parentCallback.selectAll(title);
          })}>Select All</Button>
          <Button onClick={((event: React.MouseEvent) => {
            parentCallback.deselectAll(title);
          })}>Deselect All</Button>
          </Box>
        {/* this part handles the population of options as a multiple select */}
        {Array.isArray(item) && item.map((name) => (name?.key ?
          <MenuItem key={name.key} value={name.value} onClick={handleChange.bind(this, name)} disabled={name.disabled}>
            <Checkbox checked={name.checked} />
            <ListItemText primary={name.value} />
          </MenuItem>
          : <MenuItem key={name.key} value={name.value} disabled>
          </MenuItem>
        )
        )}
      </Select>
    </FormControl>
  );
}


/**
 * To determine the render text
 * @param selected 
 * @param item 
 * @param title 
 * @returns the render text
 */
type MULTI_SELECT_TYPE = { key: string, value: string, checked: boolean };
function renderTextHelper(item: any, title: string, forFilter: boolean) {
  if (item?.length > 0 && item?.every((x: MULTI_SELECT_TYPE) => x.checked)) {
    return `All ${title}`;
  }
  else {
    const selected = item?.filter((y: MULTI_SELECT_TYPE) => y.checked);
    let renderText = selected[0]?.value;
    if (selected?.length === 1) return `${renderText}`;
    else if (selected?.length > 1) return `${renderText} + (${selected.length - 1} ${`${selected.length > 2 ? 'others' : 'other'}`})`;
    return `${forFilter ? `No ${title} selected` : `${title}`}`;
  }
}

export default MultipleSelect;