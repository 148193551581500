import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './date-range.css';
import { useState } from 'react';
import { SELECTIONRANGE } from '../_models/daterange';
// ANT Design
import { DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs'
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack'
const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const DateRange = ({ selectionRange, parentCallback }: { selectionRange: SELECTIONRANGE, parentCallback: any }) => {
    const [dates, setDates] = useState<RangeValue>([dayjs(selectionRange.startDate), dayjs(selectionRange.endDate)]);
    const handleSelect = (ranges: any) => {
        if (ranges){
            const selectedDate = {
                startDate: ranges?.[0].toDate(),
                endDate: ranges?.[1].toDate(),
            }
            setDates(ranges)
            parentCallback(selectedDate)
        }

    }

    return (
        <Stack sx={{ marginLeft: 2 }}>
            <InputLabel sx={{ fontSize: '0.75rem', marginLeft: 1, bottom: '0.55rem' }} htmlFor='flight-log-date-range'>Select Date Range
            </InputLabel>
            <RangePicker
                defaultValue= {dates}
                onCalendarChange={(val) => setDates(val)}
                format='DD/MM/YYYY'
                onChange={(val) => handleSelect(val)}
                style={{padding: '10px 10px', bottom: '8px'}}
            />
        </Stack>
    )
}

export default DateRange;