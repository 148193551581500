import { ROLE_MODEL } from "../_models/user";
import { ConstantService } from '../_services/constant.service';

export async function getUserPermission(role: string, project: string): Promise<string[]> {
    const roles: ROLE_MODEL[] = await ConstantService.getRole(project);
    return new Promise((resolve, reject) => {
        if (roles) {
            let permission;
            // if (project) {
                permission = roles.flatMap(x => x.roleName === role.toLowerCase() 
                && x?.company === project?.toLowerCase() ? x.permission : []);
            // } else {
            //     permission = roles.flatMap(x => x.roleName === role.toLowerCase() && !x.company? x.permission : []);
            // }
            localStorage.setItem('permission', JSON.stringify(permission));
            resolve(permission);
        } else {
            reject([]);
        }
    });
}

export function getPermission(): string {
    return localStorage.getItem('permission') ?? '';
}

export function isAuthorised(id: string | string[]) {
    // check if multiple ids
    if (!Array.isArray(id)) {
        // single id, immediate compare
        return getPermission().includes(id) ?? false;
    } else {
        // multiple ids, loop through and compare
        // if result is true, break out of loop.
        let result = false;
        for (const data of id) {
            if (result) {
                break;
            }
            result = getPermission().includes(data);
        }
        return result;
    }

}

export function getUserRole() {
    return sessionStorage.getItem('custom:role') as string ?? '';
}

export function getUserToken() {
    return sessionStorage.getItem('accessToken') as string ?? '';
}

export function getUserProject() {
    return sessionStorage.getItem('custom:project') as string;
}

export function getUserCompany() {
    return sessionStorage.getItem('custom:company') as string;
}

export function getUserLastLogin() {
    return sessionStorage.getItem('custom:lastLogin') as string ?? '';
}