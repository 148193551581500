import './App.css';
import Box from '@mui/material/Box';
import MainPage from './main-page/main-page';
import FlightLogs from './flight-logs/flight-logs';
import { Routes, Route } from "react-router-dom";
import { AdminConsole } from './admin/admin-console';
import Documentation from './documentation/documentation';
import Dashboard from './dashboard/dashboard';
import Maintenance from './maintenance/maintenance';
import SQN from './sqn/sqn';
import GuardedRoute from './ProtectedRoutes';
import { isAuthorised } from './_services/permission.service';
import { MODULE_ID } from './_services/constant.service';
import { usePermission } from './_contexts/PermissionContext';

function routeAccessible(id: string, userPermission: string[]) {
  return userPermission.includes(id);
}

function App() {
  const { permission } = usePermission();
  /**
   * GuardedRoute determine if route is accessible based on isAuthorised function by passing in the moduleId of the route
   */
  console.log('env: ', process.env.NODE_ENV)
  return (
    <Box>
      <Routes>
        <Route path='/*' element={<MainPage />}>
          
        </Route>
      </Routes>
    </Box>
  );
}

export default App;
