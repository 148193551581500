import axios from "axios";
import { IFlightLog } from "../_models/flightLog";
import { environment } from "../environment";
import { ConstantsEnum } from "./constant.service";

const apiUrl = environment.apiurl;
const apiEndpoint = '/flightLog';

export namespace FlightLogService {
    export function getFlightLog(dateTimeRange: any, regionName: string = '') {
        let params;
        if (regionName === undefined || regionName === ConstantsEnum.ALL_VENUE) {
            params = { startDate: encodeURIComponent(dateTimeRange.startDate), endDate: encodeURIComponent(dateTimeRange.endDate) };
        } else {
            params = { startDate: encodeURIComponent(dateTimeRange.startDate), endDate: encodeURIComponent(dateTimeRange.endDate), regionName: regionName };
        }
        return axios.get(`${apiUrl}${apiEndpoint}`, { params });
    }

    export function insertToPostgresJiraDnol(data: any) {
        return axios.post(`${apiUrl}/db/insertToPostgresJiraDnol`, data);
    }

    export function updatePostgresOperatorLogTable(data: any) {
        return axios.put(`${apiUrl}/db/updatePostgresOperatorLogTable`, data);
    }
    
    export function deleteLogFromPostgresTable(key: string) {
        return axios.delete(`${apiUrl}/db/deleteLogFromPostgresTable`, { data: {key}});
    }
}
