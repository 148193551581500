import {
    Card,
    Space
} from 'antd';
import CardContent from '@mui/material/CardContent';
// import { Box, CardHeader, Divider } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import UserTable from "./user-table/user-table";
import SideNav from './side-nav/side-nav';
import { SideNavType } from '../_models/side-nav';
import { useParams } from 'react-router-dom';
import RegistrationForm from './registration/registration';
import { CloudUploadOutlined, TeamOutlined, UserAddOutlined } from '@ant-design/icons';
import UploadToS3 from './upload/upload';
import './admin-console.css';

// const Main_Card_Sx = { minWidth: 250, width: '75%', margin: '0 auto', borderRadius: '1%' };

// To populate the side nav.
const sideNavList: SideNavType = [
    { 'key': `${(Math.random() + 1)}_userManagement`, 
    'label': 'User Management', 
    'icon': <TeamOutlined />, 
    href: '/admin_console/user_management' },
    { 'key': `${(Math.random() + 1)}_registration`, 
    'label': 'Registration', 
    'icon': <UserAddOutlined />, 
    href: '/admin_console/registration' },
    // { 'key': `${(Math.random() + 1)}_upload`, 
    // 'label': 'Upload to S3', 
    // 'icon': <CloudUploadOutlined />, 
    // href: '/admin_console/upload' }
];

// load the relevant components on click of the side nav
// Key is based on id retrieved from the url params
const componentsToLoad = {
    'user_management': 
    { 
        'title': 'User Management', 
    'element': <UserTable /> 
},
    'registration': { 
        'title': 'Registration Form', 
        'element': <RegistrationForm /> 
    },
    // 'upload': {
    //     'title': 'Upload to S3', 
    //     'element': <UploadToS3 /> 
    // }
};

const AdminConsole = () => {
    const [sideNav, setSideNav] = useState<SideNavType>(sideNavList);
    let params = useParams();
    const [components, setComponent] = useState<{ title: string, element: ReactNode }>();

    // To load components dynamically based on the params
    useEffect(() => {
        const id: string = params.id as string;
        setComponent(componentsToLoad?.[id as keyof typeof componentsToLoad]);
    }, [params])

    // To set the sidenav. Sidenav don't populate unless I set it again... -_-
    useEffect(() => {
        setSideNav([...sideNav]);
    }, [sideNav])
    return (
        <Space.Compact block>
            <SideNav items={sideNav} />
            {components ?
                <Card
                    className='admin-console-card'
                    title={components.title}>
                    {
                    components?.element 
                    ?? <></>
                    }
                </Card> :
                 <></>}
        </Space.Compact>
    );
};

export { AdminConsole };