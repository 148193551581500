import { useState } from "react";
import { PermissionContext } from "../_contexts/PermissionContext";
import { UserTableGenericType } from "../_models/user";

const basePermission: string[] = JSON.parse(localStorage.getItem('permission') ?? '[]');

export const PermissionProvider = ({ children }: any) => {
    const [permission, setPermission] = useState<string[]>(basePermission);
    const sharedState = {
        permission,
        setPermission,
        reset: () => setPermission([])
    };
    return <PermissionContext.Provider value={sharedState}>
        {children}
    </PermissionContext.Provider>
}