import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { format, parseJSON } from 'date-fns';
import { BuildContentModel, ReportPDFModel, SelectedDateRangeType, StringOrStringArray } from '../_models/report';
import { UtilService } from '../_services/utils.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { IAircraftType, IFlightLog } from '../_models/flightLog';

// Start of Helper Fn //
/**
 * To create the content body to be used for generating excel/pdf. 
 * @param data the required data in BuildContentModel format. Header = the first row (title row), body = data row.
 * @param sort to sort or not to sort the data row... provided it's working :P still WIP 
 * @param type useless, the idea here is to allow this function to handle multiple different type of info to be used for generation of PDF/Excel but yes, useless. 
 * @returns the contentbody to be used to generate excel/pdf.
 */
const buildContentBody = (data: BuildContentModel, sort = false, type = 'flight log') => {
  const keyAsHeader = data.header;
  let items = data.data
  let output: any = [];
  let dataRow: string[] = [];
  // Sort by date in desc order. WIP to use sorting function based on sort criteria
  // if (sort) items.sort((a: any, b: any) => new Date(b.takeoffTime).getTime() - new Date(a.takeoffTime).getTime());

  // Produce table content for flight logs PDF
  if (type === 'flight log') {
    output.push(['S/N', ...keyAsHeader.map((keyValue => keyValue.value))]);
    items.forEach((item: any, i: number) => {
      dataRow = [];
      dataRow.push((i + 1).toString())
      keyAsHeader.forEach((header: { key: string, value: string }) => {
        const key = header.key;
        if (Object.hasOwn(item, key) && !!item[key]) {
          if (key === 'flightDate') dataRow.push(format(new Date(item[key]), 'dd/MM/yyyy'));
          else if (key === 'flightTime') dataRow.push(UtilService.transformFlightTime(UtilService.convertMSToTimeFormat(Number(item[key]))));
          else dataRow.push(item[key]);
        } else {
          dataRow.push('-')
        }
      });
      output.push(dataRow);
    });
  }
  return output;
}

/**
 * To transform some of the data in listOfFlightLogs to be used for PDF/Excel generation due to some raw data.
 * Such as formatting takeoff/landing to HH:mm, formatting battery2 to a string as it's an array. 
 * @param logs flight logs passed in from listOfFlightLog state
 * @returns a new listOfFlightLogs
 */
export function transformLogs(logs: IFlightLog[], aircraftTypes: IAircraftType) {
  return logs.map((data: IFlightLog) => {
    let obj: {[key: string]: string} = {};
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value) && value?.length > 0) obj[key] = value.toString().split(',').join(', ').trim();
      else if (['takeoffTime', 'groundTime'].includes(key)) obj[key] = format(parseJSON(value), 'HH:mm'); 
      else if (key === 'aircraftType')  obj[key] = aircraftTypes[value];
      else obj[key] = value;
    });
    return obj;
  });
}

/**
 * To create the pdf payload for use in generating the pdf document definition.
 * @param selectedDateRange the selected date range passed in from selectedDateRange state
 * @param selectedReservoir the selected reservoir passed in from newReservoir state
 * @param data the flight log data passed in from listOfFlightLog state
 * @returns the pdf payload based on ReportPDFModel for use in generating the pdf document definition.
 */
export function buildPdfPayload(selectedDateRange: SelectedDateRangeType, selectedReservoir: string, data: StringOrStringArray): ReportPDFModel {
        return {
          selectedDateRange: {startDate: format(parseJSON(selectedDateRange.startDate), 'dd/MM/yyyy'), endDate: format(parseJSON(selectedDateRange.endDate), 'dd/MM/yyyy')},
          selectedReservoir,
          data
        };
}
// End of Helper Fn

/**
 * To create the document definition for pdfmake to generate pdf.
 * @param reportData report payload that is generated by the buildPdfPayload fn
 * @returns the document definition required for pdfmake to generate the pdf.
 */
const getDocumentDefinition = (reportData: ReportPDFModel): any => {
  let content = [];
  // Report period
  content.push({
    text: [
      'Flight Log period for ',
      {
        text: reportData.selectedDateRange.startDate,
        decoration: 'underline'
      },
      ' to ',
      {
        text: reportData.selectedDateRange.endDate,
        decoration: 'underline'
      }
    ]
  });

  content.push({
    text: reportData.selectedReservoir,
    style: 'header'
  })

  if (reportData.data?.length !== 0) {
    content.push({
      text: 'Flight Log',
      style: 'subheader'
    },
      {
        table: {
          headerRows: 1,
          widths: 'auto',
          body: reportData.data,
        }
      });
  }

  return {
    info: {
      title: 'Flight Log',
    },
    pageOrientation: 'landscape',
    content: content,
    defaultStyle: {
      fontSize: 8
    },
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [0, 20, 0, 0]
      },
      subheader: {
        fontSize: 12,
        bold: true,
        decoration: 'underline',
        margin: [0, 20, 0, 10]
      }
    }
  };
}

/**
 * To generate and download excel
 * @param name To be used for worksheet naming.
 * @param headerInfo the header info from the content body position 0 generated by the buildContentBody function.
 * @param dataInfo the data from content body position 1 onwards generated by buildContentBody function. 
 */
const exportExcel = (name: string, headerInfo: string[], dataInfo: string[]) => {
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet(name);
  worksheet.columns = headerInfo;
  worksheet.addRow(headerInfo);
  
  worksheet.addRows(dataInfo);
  workbook.xlsx.writeBuffer().then((data: any) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, `Flight Logs ${format(new Date(), 'dd_MM_yyyy')}.xlsx`);
  });
}

/**
 * To generate and download the pdf based on the report payload.
 * @param reportData report payload generated by buildPdfPayload
 */
const generateReport = (reportData: any) => {
  const documentDefinition = getDocumentDefinition(reportData);
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  // pdfMake.createPdf(documentDefinition).download(`Flight Logs ${format(new Date(), 'dd_MM_yyyy')}`);
  pdfMake.createPdf(documentDefinition).open();
}

export { generateReport, buildContentBody, exportExcel };