import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useReducer,
    useState
} from 'react';
// Libraries
// Date Time Lib Imports
import moment from 'moment';
import enLocale from 'date-fns/locale/en-US';
import { format, parseJSON, getHours} from 'date-fns';
// MUI import - Importing directly from the class instead of the library to reduce bundle size.
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
// import { SelectChangeEvent } from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
// End of Libraries
// Start of Components
import { BackdropComponent } from "../_components/backdrop";
import { DataTable } from "../_components/data-table";
import DateRange from "../_components/date-range";
import ComboBox from '../_components/combobox';
import Toastbox from '../_components/toastbox';
import MultiComboBox from '../_components/multi-combobox';
// Services
import { UtilService } from '../_services/utils.service';
import { FlightLogService } from "../_services/flightLog.service";
import {
    generateReport,
    buildContentBody,
    exportExcel,
    buildPdfPayload,
    transformLogs
} from '../_services/report.service';
import JiraService from '../_services/jira.service';
import {
    ConstantsEnum,
    ConstantService
} from '../_services/constant.service';
import { isAuthorised, getUserCompany } from '../_services/permission.service';
import { TextService } from '../_services/text.service';
// Models
import {
    DropdownSelectKeyValue,
    MultiDropdownSelectKeyValue
} from '../_models/dropdown';
import MultipleSelect from '../_components/multiple-select';
import Grid from '@mui/material/Grid/Grid';
// Types
import { SelectedDateRangeType } from '../_models/report';
import {
    IAircraftType,
    IFlightLog,
    IFlightLogTable,
    IBaseFlightLogForm
} from '../_models/flightLog';
import { TOASTBOX } from '../_models/toastbox';
import { useUser } from '../_contexts/UserContext';
import { CopyOutlined } from '@ant-design/icons';


// Style Constants
const MULTI_SEL_MIN_WIDTH: number = 150;
const MULTI_SEL_MAX_WIDTH: number = 225;
const MULTI_SELECT_STYLE = { m: 1, width: MULTI_SEL_MAX_WIDTH, minWidth: MULTI_SEL_MIN_WIDTH, maxWidth: MULTI_SEL_MAX_WIDTH };

// Helper fn

/**
* Data retrieved from API comes as battery1Id and battery2Id instead of an array to be used in the Form - Multiselect itself. 
* This transformation should be done at API level... but I do this here for now...
* @param flightLogData data from API. 
* @returns transformed data in the format the form expects.
*/
function transformFlightLogToFormData(flightLogData: IFlightLogTable) {
    let logData: any = {};
    Object.entries(flightLogData).forEach((entries: [string, string | string[]]) => {
        if (entries[0] !== 'batteryId') logData[entries[0]] = entries[1];
    });
    return logData;
}

function createRemoveObj(oldData: IFlightLog, newData: IFlightLog) {
    return Object.entries({ ...oldData, ...newData }).reduce((res, [key, value]) => {
        const keyName = key as keyof typeof oldData;
        const oldValue = oldData[keyName];
        if (Array.isArray(oldValue)) {
            const newValue = value as string[];
            if (oldValue.length > newValue.length) {
                const diff: string[] = UtilService.diffArray(oldValue, newValue);
                res[key] = [...res[key] ?? [], ...diff]
            }
        } else {
            if (oldValue !== value) res[key] = value;

        }
        return res;
    }, {} as { [key: string]: any });
}

function filterMultiOptions(options: MultiDropdownSelectKeyValue[], index: string, bool: boolean) {
    return options.filter((options: any) => {
        if (options.key === index) {
            options.checked = bool;
            return options
        }
        return options;
    });
}

function removeBlankKeys(objs: MultiDropdownSelectKeyValue[]) {
    return objs.flatMap(projId => projId.key ? projId : []);
}

function createMultiSelectFilterList(list: any, key: string, title: string) {
    return removeBlankKeys(list.reduce((result: MultiDropdownSelectKeyValue[], logs: IFlightLog) => {
        if (key === 'am/pm') {
            // handle am/pm filter
            let obj: MultiDropdownSelectKeyValue = {
                key: '',
                value: '',
                checked: false
            };
            if (getHours(new Date(logs.takeoffTime)) < 12) {
                // result.push({ key: 'AM', value: 'AM', checked: true });
                obj = { key: 'AM', value: 'AM', checked: true };
            } else if (getHours(new Date(logs.takeoffTime)) >= 12) {
                // result.push({ key: 'PM', value: 'PM', checked: true });
                obj = { key: 'PM', value: 'PM', checked: true };
            }

            if (obj.key && !result.some(x => x.key === obj.key)) {
                result.push(obj);
            }
        } else {
            // handle rest of filter
            const logsKey = key as keyof typeof logs;
            const itemExistIndex = result.findIndex(z => z.key === logs[logsKey]);
            const value = logs[logsKey] as string;
            if (itemExistIndex === -1) {
                result.push({ key: value, value: value, checked: true });
            }
            if (!value && result.findIndex(z => z.key === 'rest') === -1) {
                result.push({ key: 'rest', value: `Rest of ${title}`, checked: true });
            }
        }
        return result.sort((a, b) => UtilService.Sorter.defaultSort(a?.value, b?.value));
    }, []));
}

function updateMultiFilterLogsOnRetrieveLogs(flightLog: IFlightLog[], key: string, title: string) {
    const mappedProject = createMultiSelectFilterList(flightLog, key, title);
    return mappedProject?.length > 0 ? mappedProject : [...mappedProject, { key: '', value: '', checked: true }];
}

async function getFlightData(selectedDateRange: SelectedDateRangeType, project: string[]): Promise<IFlightLog[]> {
    const data = await Promise.all([
        JiraService.getOperatorLog(format(selectedDateRange.startDate, 'yyyy-MM-dd'), 
        format(selectedDateRange.endDate, 'yyyy-MM-dd'), project),
        FlightLogService.getFlightLog(selectedDateRange),
    ]);
    if(data[0].data) {
        data[0].data.map( value => {
            if(typeof value.cargoWeight === "number") {
                value.cargoWeight = value.cargoWeight.toFixed(1).toString() + " kg";
                return value;
            }
        });
    }
    let mergeJiraMongoData: IFlightLog[] = [];
    if (data?.length > 0 && data[0].data) {
        // return a new array consisting of the merged of jira and difference btwn both data
        mergeJiraMongoData = data[0].data?.map((opLog: any) => {
            const mongoObj = data[1].data?.findLast((x: any) => opLog.cfmsFlightId === x.cfmsFlightId)
            return { ...mongoObj, ...opLog };
        });
    }
    // add a key property to the obj because data table requires a key to properly track table changes
    return UtilService.addRowKeyToTableItems(mergeJiraMongoData ? mergeJiraMongoData : []);
}

const filterTailNumByAircraft = (text: string, fuselageList: string[]) => {
    return text ? fuselageList.filter((fuselage: string) => fuselage.toString().substring(0, 2) === text) : fuselageList;
}

const filterConstantsByProject = (constants: [{ value: string, project: string[] }], project: string) => {
    return constants.reduce((newArr: string[], current: any) => {
        if (current.project.includes(project)) newArr.push(current.value)
        return newArr;
    }, []);
}

// End of Helper fn
// Types 
type dropdownSelectType = { [key: string]: DropdownSelectKeyValue[] & string[] };
type MULTIPLE_SELECT_OPTIONS = Array<string>;
// End of Types
// Base data
let aircraftTypes: IAircraftType = {};

const baseFlightLogFormObj: IBaseFlightLogForm = {
    jiraId: '',
    flightDate: new Date(),
    aircraftType: '',
    uavId: '',
    armsId: '',
    payloadId: '',
    cargoWeight: 0,
    battery1Id: '',
    battery2Id: new Array<string>(),
    project: '',
    operation: '',
    venue: '',
    pilotId: '',
    externalPilotId: '',
    takeoffTime: new Date(),
    groundTime: new Date(),
    flightTime: 0,
    droHubId: '',
    droPortId: '',
    gcsVersion: 0,
    fccVersion: 0,
    droPortVersion: 0,
    coverageArea: 0,
    cfmsFlightId: '',
    remarks: '',
    label: '',
    creationDate: new Date(),
} as const;

/**
 * flightLogTableColumns is used to generate the table for Flight Log. For more info, read up on the Ant-D documentation for the library on the data table component.
 */
const tableSortDirection = ['descend', 'ascend', 'descend'];
const flightLogTableArray = [
    {
        title: 'Date',
        dataIndex: 'flightDate',
        key: 'flightDate',
        defaultSortOrder: 'descend',
        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => new Date(a.flightDate).getTime() - new Date(b.flightDate).getTime(),
        render: (text: string) => TextService.handleTextData(text ? format(parseJSON(new Date(text)), 'dd/MM/yyyy') : '-')
    },
    {
        title: 'Aircraft Type',
        dataIndex: 'aircraftType',
        key: 'aircraftType',
        render: (text: string) => TextService.handleTextData(aircraftTypes[text])
    },
    {
        title: 'Tail Num',
        dataIndex: 'uavId',
        key: 'uavId',
        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.uavId, b.uavId),
        render: TextService.handleTextData
    },
    {
        title: 'Motor Arms',
        dataIndex: 'armsId',
        key: 'armsId',
        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.armsId, b.armsId),
        render: TextService.handleTextData
    },
    {
        title: 'Payload',
        dataIndex: 'payloadId',
        key: 'payloadId',

        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.payloadId, b.payloadId),
        render: TextService.handleTextData
    },
    {
        title: 'Cargo Weight',
        dataIndex: 'cargoWeight',
        key: 'cargoWeight',

        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.cargoWeight.toString(), b.cargoWeight.toString()),
        render: TextService.handleTextData
    },
    {
        title: 'Battery 1',
        dataIndex: 'battery1Id',
        key: 'battery1Id',

        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.battery1Id, b.battery1Id),
        render: TextService.handleTextData
    },
    {
        title: 'Battery 2',
        dataIndex: 'battery2Id',
        key: 'battery2Id',

        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.battery2Id[0], b.battery2Id[0]),
        render: ((text: string[]) => TextService.handleTextData(text ?? ''))
    },
    {
        title: 'DroPort S/N',
        dataIndex: 'droPortId',
        key: 'droPortId',

        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.droPortId, b.droPortId),
        render: ((text: string[]) => TextService.handleTextData(text ?? ''))
    },
    {
        title: 'Project',
        dataIndex: 'project',
        key: 'project',

        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.project, b.project),
        render: TextService.handleTextData
    },
    {
        title: 'Operation',
        dataIndex: 'operation',
        key: 'operation',

        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.operation, b.operation),
        render: TextService.handleTextData
    },
    {
        title: 'Venue',
        dataIndex: 'venue',
        key: 'venue',

        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.venue, b.venue),
        render: TextService.handleTextData
    },
    {
        title: 'Pilot',
        dataIndex: 'pilotId',
        key: 'pilotId',

        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.pilotId, b.pilotId),
        render: TextService.handleTextData
    },
    {
        title: 'External Pilot',
        dataIndex: 'externalPilotId',
        key: 'externalPilotId',

        sortDirections: tableSortDirection,
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.textSort(a.externalPilotId, b.externalPilotId),
        render: TextService.handleTextData
    },
    {
        title: 'Take-off',
        dataIndex: 'takeoffTime',
        key: 'takeoffTime',
        sortDirections: tableSortDirection,
        defaultSortOrder: 'descend',
        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.timeSort(parseJSON(a.takeoffTime).getTime(), parseJSON(b.takeoffTime).getTime()),
        render: (text: string) => TextService.handleTextData(text ? format(parseJSON(text), 'HH:mm') : '')
    },
    {
        title: 'Land',
        dataIndex: 'groundTime',
        key: 'groundTime',
        sortDirections: tableSortDirection,

        sorter: (a: IFlightLogTable, b: IFlightLogTable) => UtilService.Sorter.timeSort(parseJSON(a.groundTime).getTime(), parseJSON(b.groundTime).getTime()),
        render: (text: string) => TextService.handleTextData(text ? format(parseJSON(text), 'HH:mm') : '')
    },
    {
        title: 'Flight Time',
        dataIndex: 'flightTime',
        key: 'flightTime',
        render: (text: string) => TextService.handleTextData(UtilService.transformFlightTime(UtilService.convertMSToTimeFormat(Number(text))))
    },
    {
        title: 'FCC Version',
        dataIndex: 'fccVersion',
        key: 'fccVersion',
        render: (text: string) => TextService.handleTextData(!text || isNaN(Number(text)) ? text : Number(text)?.toFixed(2).toString())
    },
    {
        title: 'GCS Version',
        dataIndex: 'gcsVersion',
        key: 'gcsVersion',
        render: (text: string) => TextService.handleTextData(!text || isNaN(Number(text)) ? text : Number(text)?.toFixed(2).toString())
    },
    {
        title: 'DroPort Version',
        dataIndex: 'droPortVersion',
        key: 'droPortVersion',
        render: (text: string) => TextService.handleTextData(!text || isNaN(Number(text)) ? text : Number(text)?.toFixed(2).toString())
    },
    {
        title: 'DroHub S/N',
        dataIndex: 'droHubId',
        key: 'droHubId',
        render: (text: string) => TextService.handleTextData(!text || isNaN(Number(text)) ? text : Number(text)?.toFixed(2).toString())
    },
    // {
    //     title: 'Coverage Area',
    //     dataIndex: 'coverageArea',
    //     key: 'coverageArea',
    //     render: (text: string) => TextService.handleTextData(text && !isNaN(Number(text)) ? `${Number(text)?.toFixed(2).toString()}ha` : '')
    // },
    {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        render: TextService.handleTextData,
    }
];
const flightLogTableColumns: any[] = [];
flightLogTableColumns.push(...flightLogTableArray);

// Components

// Modals
/**
 * A modal component for deleting flight log records. Future plan - to make a generic modal component.
 * @param open - determine it's open/close state.
 * @param handleModalClose - callback when closing the modal
 * @param handleModalOnSubmit - callback when submitting
 * @param idToDelete - the flight log id to delete
 * @returns - a Modal component to pop up.
 */
const DelModalComponent = ({ open, handleModalClose, handleModalOnSubmit, idToDelete}: any) => {
    // Get Context
    let [submitting, setSubmitting] = useState(false);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitting(true);
        const res = await JiraService.deleteOperatorLog([idToDelete]);
        if (res.status === 200) {
            // remove from postgres if successfully invalidated in JIRA
            const deleteFromPgRes = await FlightLogService.deleteLogFromPostgresTable(idToDelete);
            setSubmitting(false);
            handleModalOnSubmit('delete')
        }
    }
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    return (
        <>
            <Modal
                open={open}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Delete Flight Log
                    </Typography>
                    <Typography pt={1} id="modal-modal-body" variant="body1" component="p">
                        Are you sure you want to delete this flight log?
                    </Typography>
                    <Box pt={3} onSubmit={onSubmit}
                        component="form"
                        noValidate
                        autoComplete="off">
                        <Button variant="outlined" onClick={() => { handleModalClose('delete');}}>
                            Cancel
                        </Button>
                        <Button variant="contained" className="float-right" type="submit">
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <BackdropComponent submitting={submitting} />
        </>
    )
}

/**
 * A modal component for deleting flight log records. Future plan - to make a generic modal component.
 * WIP in making generic components to reduce repeating codes... Dropdown is done, textfield, date, time in the pipeline.
 * @param open - determine it's open/close state.
 * @param handleModalClose - callback when closing the modal
 * @param handleModalOnSubmit - callback when submitting
 * @param flightLogFormData - The Flight Log data to populate for editting. Will be a using baseFlightLogFormObj as the base obj if adding new flight log.
 * @param isEdit - determine whether is it in edit mode for updating of flight logs.
 * @param constantsObj - List of constants (e.g. reservoir list, operation list, etc)
 * @returns - a Modal component to pop up.
 */
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 800,
    maxWidth: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const ModalComponent = ({ open, handleModalClose, handleModalOnSubmit, flightLogFormData, isEdit, constants }: any) => {
    const hasData = !!flightLogFormData.armsId;
    const labelInputProps = { shrink: true };
    const formState = hasData ? flightLogFormData : baseFlightLogFormObj;
    const dropdownFieldNameToKeyMap: { [key: string]: string } = {
        'tail num': 'uavId',
        'aircraft type': 'aircraftType',
        'motor arms': 'armsId',
        'payload': 'payloadId',
        'battery': 'battery1Id',
        'additional battery': 'battery2Id',
        'project': 'project',
        'operation': 'operation',
        'venue': 'venue',
        'pilot': 'pilotId',
        'external pilot': 'externalPilotId',
        'drohub s/n': 'droHubId',
        'droport s/n': 'droPortId',
    };
    function flightLogFormReducer(state: IFlightLog, action: { type: string, payload: {} }): IFlightLog {
        let currentState: IFlightLog = state;
        if (action.type === 'UPDATE') currentState = { ...state, ...action.payload };
        return currentState;
    }

    // Battery functions
    const updateBatteryAsset = (key: string | string[]) => constants.battery.reduce((result: string[], batt: string) => {
        if (batt !== mainBattery) {
            if (Array.isArray(key)) {
                if (!key.includes(batt)) result.push(batt);
            } else {
                if (key !== batt) result.push(batt);
            }
        }
        return result;
    }, [] as string[]);

    /**
     * loops through the newly created additional battery list from constants.battery through the use of reduceBattery and getAdditionalBattery function.
     * To compare existing value and ensure what was selected doesn't get replaced by the newly created list.
     * @param selected selected battery key
     * @returns 
     */
    function updateAdditionalBatteryList(selected: string) {
        return additionalBattery.filter(x => x !== selected);
    }
    //  ENd of Battery functions
    // current modal form data state.
    const [flightLogFormState, setFlightLogFormState] = useReducer(flightLogFormReducer, formState);
    let [errors, setErrors] = useState<{ [key: string]: string | string[] }>({});
    let [batteryAsset, setBatteryAsset] = useState<string[]>(constants?.battery ?? []);
    let [mainBattery, setMainBattery] = useState<string>();
    let [additionalBattery, setAdditionalBattery] = useState<string[]>(batteryAsset);
    let [submitting, setSubmitting] = useState(false);
    let [modalConstants, setModalConstants] = useState({ ...constants });
    let [toastState, setToastState] = useState<TOASTBOX>({ openCloseState: false, message: '', type: 'success', duration: 5000, callback: () => false });
    /**
     *  Filter Tail Num based on flightLogFormState/flightLogFormData's aircraftType data if isEdit = true ONCE/ON FIRST LOAD. 
     */
    const populateConstants = (value: string) => {
        const newVenue = filterConstantsByProject(constants['venue'], value);
        const newActivity = filterConstantsByProject(constants['activity'], value);
        const filteredFuselageList = filterTailNumByAircraft(flightLogFormState.aircraftType, constants['fuselage']);
        setModalConstants({ ...modalConstants, venue: newVenue, activity: newActivity, fuselageList: filteredFuselageList })
    }
    useEffect(() => {
        // do whatever you need here on first load of the modal.
        populateConstants('');
        if (hasData) {
            populateConstants(flightLogFormState?.project);
            const mainBatt = updateBatteryAsset(flightLogFormState?.battery2Id ?? '')
            const addBatt = updateAdditionalBatteryList(flightLogFormState?.battery1Id ?? '');
            setBatteryAsset(mainBatt);
            setAdditionalBattery(addBatt);
        }
    }, [])

    /**
     * When form has been submitted, to show a success toast message. 
     * WIP - to handle the different HTTP status and show the relevant toast type and msg
     * @param isEdit mode
     */
    const afterFormSubmit = (isEdit: boolean) => {
        const toastResult: TOASTBOX = { ...toastState, openCloseState: true, message: `You have successfully submitted the ${!isEdit ? 'log' : 'changes'}.`, type: 'success' };
        handleModalOnSubmit('form', toastResult);
        if (!open) setSubmitting(false);
    }

    /**
     * When form has been submitted, to show a error toast message. 
     * WIP - to handle the different HTTP status and show the relevant toast type and msg
     * @param isEdit mode
     */
    const afterFormSubmitError = (isEdit: boolean) => {
        const toastResult: TOASTBOX = { ...toastState, openCloseState: true, message: `${!isEdit ? 'Please fill all required fields correctly. Thank you.' : 'Update error.'}`, type: 'error' };
        handleModalOnSubmit('form', toastResult);
    }

    // during submission, prevent refresh of page. Create and Update Flow based on isEdit boolean.
    const handleOnSubmit = async (event: any) => {
        event.preventDefault();
        if ((!flightLogFormState.uavId) ||
            (!flightLogFormState.aircraftType) ||
            (!flightLogFormState.armsId) ||
            (!flightLogFormState.battery1Id) ||
            (!flightLogFormState.flightDate) ||
            (!flightLogFormState.groundTime) ||
            (!flightLogFormState.operation) ||
            (!flightLogFormState.project) ||
            (!flightLogFormState.takeoffTime) ||
            (!flightLogFormState.venue) ||
            (flightLogFormState.groundTime < flightLogFormState.takeoffTime)) {
            // if Tail Num is not selected when you submit, show inline error and prevent submission.
            setErrors({ ...errors, uavId: 'Please enter valid UAV Tail Num.' });
            const toastResult: TOASTBOX = { ...toastState, openCloseState: true, message: `Please fill all required fields. Thank you.`, type: 'error' };
            handleModalOnSubmit('form', toastResult);
            //if (!open) setSubmitting(false);
            return;
        }
        let jiraPayload = { ...flightLogFormState };
        try {
            if (isEdit) {
                const payload = JiraService.createOperatorLogPayload(JiraService.createUpdateOperatorLogPayload(flightLogFormData, jiraPayload), 'edit');
                if(flightLogFormState.cargoWeight && typeof flightLogFormState.cargoWeight === 'string'){
                    flightLogFormState.cargoWeight = parseFloat(flightLogFormState.cargoWeight.replace(" kg", ""))
                }
                const toBeRemoved = createRemoveObj(flightLogFormData, flightLogFormState);
                let removePayload: { [key: string]: [{ [key: string]: any }] } = {};
                let hasError: boolean = false;
                if (Object.keys(toBeRemoved)?.length > 0) {
                    // If there's item to be removed, create the removePayload.
                    removePayload = JiraService.createRemovePayload(toBeRemoved, payload, jiraPayload);
                    hasError = Object.values(removePayload)[0]?.some(x => x.error);
                    if (hasError) {
                        const error = Object.values(removePayload)[0][0].error;
                        setToastState({ ...toastState, openCloseState: true, message: error, type: 'error' });
                        return;
                    }
                }
                if ((Object.keys(payload).length > 0 || (Object.keys(removePayload).length > 0 && !hasError))) {
                    // if there's item to be removed or to be updated.
                    setSubmitting(true);
                    const jiraResp = await JiraService.updateOperatorLog(jiraPayload?.jiraId ?? '', payload, removePayload);
                    if (jiraResp.status === 200) {
                        // Retrieve the logs from JIRA using issue id, then flow towards postgres
                        const logsDataFromJiraRes = await JiraService.getJiraLogForPg(jiraPayload?.jiraId + '');
                        const updateToPgResp = await FlightLogService.updatePostgresOperatorLogTable(logsDataFromJiraRes.data[0]);
                    }
                    afterFormSubmit(isEdit);
                } else {
                    // if there's no item to be removed or to be updated. Raise a warning toastbox. 
                    setToastState({ ...toastState, openCloseState: true, message: (hasError ? 'Error' : 'There are no changes to be made.'), type: (hasError ? 'error' : 'warning') });
                }
            } else {
                // Create flow - create payload and submit.
                setSubmitting(true);
                const payload = JiraService.createOperatorLogPayload(jiraPayload, 'create');
                const jiraResp = await JiraService.addOperatorLog(payload);
                if (jiraResp.data) {
                    // flow towards postgres
                    const logsDataFromJiraRes = await JiraService.getJiraLogForPg(jiraResp.data.res.key);
                    const insertToPgResp = await FlightLogService.insertToPostgresJiraDnol(logsDataFromJiraRes.data);
                }
                afterFormSubmit(isEdit);
            }
        } catch (err) {
            console.error(err);
            afterFormSubmitError(isEdit);
        }
    };

    const handleOnCancel = () => {
        setErrors({});
        handleModalClose('form');
    }

    const handleFlightTimeChange = (event: any) => {
        setFlightLogFormState({ type: 'UPDATE', payload: { flightTime: (event.getTime() - moment().startOf('day').valueOf()) } });
    }

    const handleAircraftChange = (text: string) => {
        const filteredFuselageList = filterTailNumByAircraft(text, constants['fuselage']);
        setFlightLogFormState({ type: 'UPDATE', payload: { uavId: '' } });
        setModalConstants({ ...constants, fuselage: filteredFuselageList });
    };

    const handleMultiComboBoxChange = (value: string[], typeOfChange: string, itemName: string,) => {
        const keyName: keyof typeof flightLogFormState = dropdownFieldNameToKeyMap[itemName] as keyof typeof flightLogFormState;
        if (typeOfChange === 'selectOption' || typeOfChange === 'createOption') {
            // crafting the payload
            const payload = { [keyName]: value };
            setFlightLogFormState({ type: 'UPDATE', payload });
            if (itemName === 'additional battery') {
                const newBattery = updateBatteryAsset(value);
                setBatteryAsset(newBattery);
            }
        }
        else if (typeOfChange === 'removeOption' || typeOfChange === 'clear') {
            const payload = { [keyName]: value };
            setFlightLogFormState({ type: 'UPDATE', payload });
            if (itemName === 'additional battery') {
                const newBattery = updateBatteryAsset(value);
                setBatteryAsset(newBattery.sort());
            }
        }
    };

    const handleComboBoxChange = (value: string, title: string) => {
        setFlightLogFormState({ type: 'UPDATE', payload: { [dropdownFieldNameToKeyMap[title]]: value } });

        if (title === 'Aircraft Type'.toLowerCase()) {
            // to handle filtering of relevant Tail Num when selecting aircraft type
            handleAircraftChange(value);
        }
        // WIP for validation and error generation to be external
        else if (title === 'Tail Num'.toLowerCase()) {
            setErrors(current => {
                const { uavId, ...rest } = current;
                return rest;
            });
        }
        else if (title === 'Battery'.toLowerCase()) {
            setMainBattery(value);
            const newBatteryAsset = updateBatteryAsset(value);
            setBatteryAsset(newBatteryAsset);
            setAdditionalBattery(newBatteryAsset);
        }
        else if (title === 'Project'.toLowerCase()) {
            populateConstants(value);
        }
    }

    const handleVersioningChange = (value: string, inputTitle: string) => {
        const re = /^(?:0|[1-9]\d*)(?:\.\d{0,2})?$/g;
        if (value === '' || re.test(value)) {
            setFlightLogFormState({ type: 'UPDATE', payload: { [inputTitle]: value } })
        }
    };

    const handleToastChanges = (state: boolean) => {
        setToastState({ ...toastState, openCloseState: state })
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {!isEdit ? 'Create New Entry' : 'Edit Flight Log'}
                    </Typography>
                    <Box onSubmit={handleOnSubmit}
                        component="form"
                        noValidate
                        autoComplete="off">
                        <Box
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                        >
                            <div>
                                <TextField
                                    id="flight-id-input-required"
                                    label="Flight ID"
                                    name="cfmsFlightId"
                                    InputLabelProps={labelInputProps}
                                    placeholder='Flight ID'
                                    value={flightLogFormState.cfmsFlightId}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFlightLogFormState({ type: 'UPDATE', payload: { cfmsFlightId: event.target.value } })
                                    }
                                    }
                                />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date"
                                        inputFormat="dd/MM/yyyy"
                                        value={flightLogFormState.flightDate}
                                        onChange={(newValue: any) => setFlightLogFormState({
                                            type: 'UPDATE', payload: {
                                                flightDate: newValue,
                                                groundTime: UtilService.transformLandGroundTime(newValue, flightLogFormState.groundTime),
                                                takeoffTime: UtilService.transformLandGroundTime(newValue, flightLogFormState.takeoffTime)
                                            }
                                        })}
                                        renderInput={(params) => <TextField {...params} InputLabelProps={labelInputProps}
                                            placeholder='Date' />}
                                    />
                                </LocalizationProvider>
                                
                                <ComboBox key={`${open}_droHubId`} style={{ mr: 2, width: '25ch' }} item={modalConstants?.['droHub'] ?? []}
                                    parentCallback={handleComboBoxChange} title='DroHub S/N' data={flightLogFormState?.droHubId ?? ''} forFilter={false} freeSolo={true} />

                                <ComboBox key={`${open}_aircraftType`} style={{ mr: 2, width: '25ch' }} item={modalConstants?.['aircraftType'] ?? []}
                                    parentCallback={handleComboBoxChange} title='Aircraft Type' data={flightLogFormState?.aircraftType ?? ''} forFilter={false} freeSolo={true} transformationList={aircraftTypes} />

                                <ComboBox key={`${open}_uav`} style={{ mr: 2, width: '25ch' }} item={modalConstants?.['fuselage'] ?? []}
                                    parentCallback={handleComboBoxChange} title='Tail Num' data={flightLogFormState?.uavId ?? ''} forFilter={false} freeSolo={true} />

                                <ComboBox key={`${open}_droPortId`} style={{ mr: 2, width: '25ch' }} item={modalConstants?.['droPort'] ?? []}
                                    parentCallback={handleComboBoxChange} title='DroPort S/N' data={flightLogFormState?.droPortId ?? ''} forFilter={false} freeSolo={true} />

                                <ComboBox key={`${open}_arms`} style={{ mr: 2, width: '25ch' }} item={modalConstants?.['motor arms'] ?? []}
                                    parentCallback={handleComboBoxChange} title='Motor Arms' data={flightLogFormState?.armsId ?? ''} forFilter={false} freeSolo={true} />

                                <ComboBox key={`${open}_payload`} style={{ mr: 2, width: '25ch' }} item={modalConstants?.['payload'] ?? []}
                                    parentCallback={handleComboBoxChange} title='Payload' data={flightLogFormState?.payloadId ?? ''} forFilter={false} freeSolo={true} />

                                <TextField
                                    id="cargo-weight-input-required"
                                    label="Cargo Weight"
                                    name="cargoWeight"
                                    InputLabelProps={labelInputProps}
                                    placeholder='Cargo Weight'
                                    value={flightLogFormState.cargoWeight}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVersioningChange(event.target.value, 'cargoWeight')}
                                />

                                <ComboBox key={`${open}_battery1`} style={{ mr: 2, width: '25ch' }} item={mainBattery ? [mainBattery, ...batteryAsset] : ([...batteryAsset] ?? [])}
                                    parentCallback={handleComboBoxChange} title='Battery' data={flightLogFormState?.battery1Id ?? ''} forFilter={false} freeSolo={true} />

                                <MultiComboBox disable={true} key={`${open}_battery2`} style={{ mr: 2, width: '25ch' }} item={additionalBattery ?? []}
                                    parentCallback={handleMultiComboBoxChange} title='Additional Battery' data={flightLogFormState?.battery2Id ?? []} forFilter={false} freeSolo={true} />

                                <ComboBox key={`${open}_proj`} style={{ mr: 2, width: '25ch' }} item={modalConstants?.['project'] ?? []}
                                    parentCallback={handleComboBoxChange} title='Project' data={flightLogFormState?.project ?? ''} forFilter={false} freeSolo={true} />

                                <ComboBox key={`${open}_operation`} style={{ mr: 2, width: '25ch' }} item={flightLogFormState?.project ? (modalConstants?.['activity'] ?? []) : ['Select a Project']}
                                    parentCallback={handleComboBoxChange} title='Operation' data={flightLogFormState?.operation ?? ''} forFilter={false} freeSolo={true} disable={!flightLogFormState?.project} />

                                <ComboBox key={`${open}_venue`} style={{ mr: 2, width: '25ch' }} item={flightLogFormState?.project ? (modalConstants?.['venue'] ?? []) : ['Select a Project']}
                                    parentCallback={handleComboBoxChange} title='Venue' data={flightLogFormState?.venue ?? ''} forFilter={false} freeSolo={true} disable={!flightLogFormState?.project} />

                                <ComboBox key={`${open}_pilot`} style={{ mr: 2, width: '25ch' }} item={modalConstants?.['pilot'] ?? []}
                                    parentCallback={handleComboBoxChange} title='Pilot' data={flightLogFormState?.pilotId ?? ''} forFilter={false} freeSolo={true} />

                                <ComboBox key={`${open}_extPilot`} style={{ mr: 2, width: '25ch' }} item={modalConstants?.['externalPilot'] ?? []}
                                    parentCallback={handleComboBoxChange} title='External Pilot' data={flightLogFormState?.externalPilotId ?? ''} forFilter={false} freeSolo={true} />

                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
                                    <TimePicker
                                        openTo="hours"
                                        views={['hours', 'minutes']}
                                        inputFormat="HH:mm"
                                        mask="__:__:__"
                                        label="Take Off"
                                        value={new Date(flightLogFormState.takeoffTime)}
                                        onChange={(newValue) => setFlightLogFormState({
                                            type: 'UPDATE', payload: {
                                                takeoffTime: UtilService.transformLandGroundTime(new Date(flightLogFormState.flightDate), newValue as Date),
                                                flightTime: (flightLogFormState.groundTime && newValue) ? (new Date(flightLogFormState.groundTime).getTime() - new Date(newValue).getTime()) : ''
                                            }
                                        })}
                                        renderInput={(params) => <TextField {...params} InputLabelProps={labelInputProps}
                                            placeholder='Take Off' />}
                                    />
                                    <TimePicker
                                        minTime={new Date(flightLogFormState.takeoffTime) ?? new Date()}
                                        openTo="hours"
                                        views={['hours', 'minutes']}
                                        inputFormat="HH:mm"
                                        mask="__:__:__"
                                        label="Land"
                                        value={new Date(flightLogFormState.groundTime)}
                                        onChange={(newValue) => setFlightLogFormState({
                                            type: 'UPDATE', payload: {
                                                groundTime: UtilService.transformLandGroundTime(new Date(flightLogFormState.flightDate), newValue as Date),
                                                flightTime: (flightLogFormState.takeoffTime && newValue) ? (new Date(newValue).getTime() - new Date(flightLogFormState.takeoffTime).getTime()) : ''
                                            }
                                        })}
                                        renderInput={(params) => <TextField {...params} InputLabelProps={labelInputProps} placeholder='Land' />}
                                    />
                                    <TimePicker
                                        ampm={false}
                                        openTo="hours"
                                        views={['hours', 'minutes', 'seconds']}
                                        inputFormat="HH:mm:ss"
                                        mask="__:__:__"
                                        label="Flight Time"
                                        value={UtilService.convertTimeMSToDate(Number(flightLogFormState.flightTime) ?? '')}
                                        onChange={(event: any) => handleFlightTimeChange(event)}
                                        renderInput={(params) => <TextField {...params} InputLabelProps={labelInputProps} placeholder='Flight Time' />}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    id="fcc-version-input-required"
                                    label="FCC Version"
                                    name="fccVersion"
                                    InputLabelProps={labelInputProps}
                                    placeholder='FCC Version'
                                    value={flightLogFormState.fccVersion}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVersioningChange(event.target.value, 'fccVersion')}
                                />
                                <TextField
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]+([,\.][0-9]+)?' }}
                                    id="gcs-version-input-required"
                                    label="GCS Version"
                                    name="gcsVersion"
                                    InputLabelProps={labelInputProps}
                                    placeholder='GCS Version'
                                    value={flightLogFormState.gcsVersion}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVersioningChange(event.target.value, 'gcsVersion')}
                                />
                                <TextField
                                    id="droport-version-input-required"
                                    label="DroPort Version"
                                    name="droPortVersion"
                                    InputLabelProps={labelInputProps}
                                    placeholder='DroPort Version'
                                    value={flightLogFormState.droPortVersion}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleVersioningChange(event.target.value, 'droPortVersion')}
                                />
                            </div>
                        </Box>
                        <Box sx={{ '& .MuiTextField-root': { m: 1 } }}>
                            <div>
                                <TextField
                                    id="remarks-input-required"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    label="Remarks"
                                    name="remarks"
                                    InputLabelProps={labelInputProps}
                                    placeholder='Remarks'
                                    defaultValue={flightLogFormState.remarks}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFlightLogFormState({ type: 'UPDATE', payload: { remarks: event.target.value } })}
                                />
                            </div>
                        </Box>
                        <Button variant="outlined" onClick={() => {handleOnCancel(); }}>
                        Cancel
                        </Button>
                        <Button variant="contained" className="float-right" type="submit" >
                        Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <BackdropComponent submitting={submitting} />
            {toastState.openCloseState ? <Toastbox openCloseState={true} message={toastState.message} type={toastState.type} duration={toastState.duration} callback={handleToastChanges} /> : <></>}
        </>
    )
}

/**
 * Flight Log page.
 * @returns flight log page
 */
const FlightLogs = () => {
    const flightLogColumnsKeyValue = [
        { key: 'flightDate', value: 'Date', checked: true },
        { key: 'aircraftType', value: 'Aircraft Type', checked: true },
        { key: 'uavId', value: 'Tail Num', checked: true },
        { key: 'armsId', value: 'Motor Arms', checked: true },
        { key: 'payloadId', value: 'Payload', checked: true },
        { key: 'cargoWeight', value: 'Cargo Weight', checked: false }, 
        { key: 'battery1Id', value: 'Battery 1', checked: true },
        { key: 'battery2Id', value: 'Battery 2', checked: true },
        { key: 'droPortId', value: 'DroPort S/N', checked: true },
        { key: 'project', value: 'Project', checked: true },
        { key: 'operation', value: 'Operation', checked: true },
        { key: 'venue', value: 'Venue', checked: true },
        { key: 'pilotId', value: 'Pilot', checked: true },
        { key: 'externalPilotId', value: 'External Pilot', checked: true },
        { key: 'takeoffTime', value: 'Take-off', checked: true },
        { key: 'groundTime', value: 'Land', checked: true },
        { key: 'flightTime', value: 'Flight Time', checked: true },
        { key: 'fccVersion', value: 'FCC Version', checked: false },
        { key: 'gcsVersion', value: 'GCS Version', checked: false },
        { key: 'droPortVersion', value: 'DroPort Version', checked: false },
        { key: 'droHubId', value: 'DroHub S/N', checked: false },
        // { key: 'coverageArea', value: 'Coverage Area', checked: true },
        { key: 'remarks', value: 'Remarks', checked: true },
    ];
    const company = getUserCompany();
    if (company === 'skyports'){
        const keysToRemove = ['fccVersion', 'gcsVersion', 'coverageArea','project'];

        // Filter out the objects with specified keys from the array
        const filteredArray = flightLogColumnsKeyValue.filter((item) => {
            return !keysToRemove.includes(item.key);
        });

        flightLogColumnsKeyValue.length = 0; // Clear the original array
        flightLogColumnsKeyValue.push(...filteredArray); // Push filtered elements back into the original array

        // Filter out the objects with specified keys from the array
        const filteredTableArray = flightLogTableArray.filter((item) => {
            return !keysToRemove.includes(item.key);
        });

        flightLogTableColumns.length = 0; // Clear the original array
        flightLogTableColumns.push(...filteredTableArray); // Push filtered elements back into the original array
    }
    else{
        flightLogTableColumns.length = 0; // Clear the original array
        flightLogTableColumns.push(...flightLogTableArray); // Push filtered elements back into the original array   
    }
    // States
    let [isFetching, setFetch] = useState(true);
    let [isLoading, setLoading] = useState(true);
    let [isEdit, setEdit] = useState(false);
    let [selectedDateRange, setSelectedDateRange] = useState(UtilService.getDateRange());
    let [newReservoir, setReservoirChange] = useState<string>(ConstantsEnum.ALL_VENUE);
    let [originalFlightLog, setOriginalFlightLog] = useState<IFlightLog[]>([]);
    // listOfFlightLog is for populating table view.
    let [listOfFlightLog, setFlightLogList] = useState<IFlightLog[]>([]);
    // flightLogFormData is meant for the add new/edit form.
    let [flightLogFormData, setFlightLogFormData] = useState<IFlightLog>(baseFlightLogFormObj as IFlightLog);
    // Define the action columns for edit/del.
    const actionColumn = {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        fixed: 'right',

        render: (item: any, record: any) => (
            <Stack direction="row" >    
                {isAuthorised('FLM_002') ? 
                    <Tooltip title={`Edit`}>
                    <IconButton  aria-label="edit" color ='primary'  style={{ color: record.amendCount > 0  ? 'purple' : 'primary' }}
                    onClick={() => {handleFlightLogEdit(record);}}>
                    <EditIcon />
                    </IconButton></Tooltip> : <></>
                }
                {isAuthorised('FLM_002') ?
                    <Tooltip title="Copy">
                    <IconButton aria-label="copy" color ='primary' onClick={() => handleFlightLogCopy(record)}>
                    <CopyOutlined />
                    </IconButton></Tooltip> : <></>
                }
                {isAuthorised('FLM_003') ?
                    <Tooltip title="Delete">
                    <IconButton aria-label="delete" onClick={() => handleFlightLogDelete(record)}>
                    <DeleteIcon />
                    </IconButton></Tooltip> : <></>
                }
            </Stack>)
    };
    // End of actions column
    /**
    * Filters variables
    */
    const ampm = [
        { key: 'AM', value: 'AM', checked: true },
        { key: 'PM', value: 'PM', checked: true }
    ];
    /**
     * Start of Asset Filter Multiple Select State
     */
    let [projectList, setProjectList] = useState<MultiDropdownSelectKeyValue[]>([]);
    let [tailNumList, setTailNumList] = useState<MultiDropdownSelectKeyValue[]>([]);
    let [pilotList, setPilotList] = useState<MultiDropdownSelectKeyValue[]>([]);
    let [extPilotList, setExtPilotList] = useState<MultiDropdownSelectKeyValue[]>([]);
    let [venueList, setVenueList] = useState<MultiDropdownSelectKeyValue[]>([]);
    // End of Asset Filter Multiple Select
    let [filterOptions, setFilterOptions] = useState<MultiDropdownSelectKeyValue[]>(flightLogColumnsKeyValue);
    let [columns, setColumns] = useState(
        flightLogTableColumns.flatMap(x => filterOptions.find(z => z.key === x.key)?.checked ? x : [])
    );

    let [open, setOpen] = useState(false);
    let [delOpen, setDelOpen] = useState(false);
    let [constants, setConstants] = useState<dropdownSelectType>({});
    let [toastState, setToastState] = useState<TOASTBOX>({ openCloseState: false, message: '', type: 'success', duration: 5000, callback: () => false });
    const [ampmList, setAmpmList] = useState<MultiDropdownSelectKeyValue[]>(ampm);
    const { data } = useUser();
    // End of States    
    const filteredTableObj: { [key: string]: { setter: Dispatch<SetStateAction<MultiDropdownSelectKeyValue[]>>, key: string } } = {
        'project': { setter: setProjectList, key: 'project' },
        'tail num': { setter: setTailNumList, key: 'tailNum' },
        'pilot': { setter: setPilotList, key: 'pilot' },
        'external pilot': { setter: setExtPilotList, key: 'extPilot' },
        'venue': { setter: setVenueList, key: 'venue' },
        'am/pm': { setter: setAmpmList, key: 'am/pm' }
    };
    const titleToFilterListMap: { [key: string]: MultiDropdownSelectKeyValue[] } = {
        'project': projectList,
        'tail num': tailNumList,
        'pilot': pilotList,
        'external pilot': extPilotList,
        'venue': venueList,
        'am/pm': ampmList
    };

    const titleToFlightLogKeyMap: { [key: string]: string } = {
        'project': 'project',
        'tail num': 'uavId',
        'pilot': 'pilotId',
        'external pilot': 'externalPilotId',
        'venue': 'venue',
        'am/pm': 'am/pm'
    };

    const keyAndTitleMap: { [key: string]: { key: string, title: string } } = {
        'tailNum': { key: 'uavId', title: 'tail num' },
        'project': { key: 'project', title: 'project' },
        'pilot': { key: 'pilotId', title: 'pilot' },
        'extPilot': { key: 'externalPilotId', title: 'external pilot' },
        'venue': { key: 'venue', title: 'venue' },
        'am/pm': { key: 'am/pm', title: 'am/pm' },
    };
    /**
     * End of Filters
     */
    // Effects
    const [triggerEffect, setTriggerEffect] = useState(false);
    // To load once only.
    useEffect(() => {
        const retrieveDateOnInit = async () => {
            try {
                const activity = await ConstantService.getActivity();
                const pilot = await ConstantService.getPilot();
                const venue = await ConstantService.getVenue();
                const flightLogList = await getFlightData(selectedDateRange, data?.['custom:project']);

                const constantsData = await JiraService.loadAssets(data?.['custom:company'] ?? '');
                if (constantsData?.['aircraft type']?.length > 0) {
                    aircraftTypes = Object.assign({}, ...constantsData?.['aircraft type']?.flatMap((x: DropdownSelectKeyValue) => ({ [x.key]: x.value })));
                }
                const projectData = updateMultiFilterLogsOnRetrieveLogs(flightLogList, 'project', 'Project');
                updateLogsMultiFilter(flightLogList);
                setOriginalFlightLog(flightLogList);
                setFlightLogList(flightLogList);
                const newConstants = {
                    ...constants, venue, activity, pilot: pilot?.pilot,
                    externalPilot: pilot?.externalPilot, projectList: projectData, ...constantsData, aircraftType: Object.keys(aircraftTypes).sort()
                };
                setConstants(newConstants);
                setLoading(false);
                setFetch(false);
            } catch (error) {
                console.error('error: ', error);
            }
        }
        retrieveDateOnInit();
        setTriggerEffect(false);
    }, [triggerEffect])
    // End of Effects

    function updateLogsMultiFilter(flightLogList: IFlightLog[]) {
        setVenueList(updateMultiFilterLogsOnRetrieveLogs(flightLogList, 'venue', 'venue'));
        setProjectList(updateMultiFilterLogsOnRetrieveLogs(flightLogList, 'project', 'Project'));
        setTailNumList(updateMultiFilterLogsOnRetrieveLogs(flightLogList, 'uavId', 'Tail Num'));
        setPilotList(updateMultiFilterLogsOnRetrieveLogs(flightLogList, 'pilotId', 'Pilot'));
        setExtPilotList(updateMultiFilterLogsOnRetrieveLogs(flightLogList, 'externalPilotId', 'External Pilot'));
    }

    // Handle Events
    const handleDateChange = async (event: SelectedDateRangeType) => {
        setFetch(true);
        setSelectedDateRange(event)
        const flightLogList = await getFlightData({ startDate: event.startDate, endDate: event.endDate }, data?.['custom:project']);
        updateLogsMultiFilter(flightLogList);
        setOriginalFlightLog(flightLogList);
        setFlightLogList(flightLogList);
        setFetch(false);
    }

    const handleModalClose = (type: string) => {
        switch (type) {
            case 'form': {
                setFlightLogFormData(baseFlightLogFormObj as IFlightLog)
                setOpen(false);
                setEdit(false);
                break;
            }
            case 'delete': {
                setDelOpen(false);
                break;
            }
        }
    }

    const handleModalOpen = () => {
        setOpen(true);
    }
    const handleModalOnSubmit = async (type: string, toastResult: TOASTBOX) => {
        const flightLogList = await getFlightData(selectedDateRange, data?.['custom:project']);
        setToastState({ ...toastState, ...toastResult })
        setTriggerEffect(true);
        setFlightLogList(flightLogList);
        updateLogsMultiFilter(flightLogList);
        handleModalClose(type);
    }

    const handleFlightLogEdit = (data: IFlightLogTable) => {
        const formData: IFlightLog = transformFlightLogToFormData(data);
        if(formData.cargoWeight && typeof formData.cargoWeight === 'string'){
            formData.cargoWeight = parseFloat(formData.cargoWeight.replace(" kg", ""))
        }
        setFlightLogFormData(formData);
        setEdit(true);
        handleModalOpen();
    };

    const handleFlightLogCopy = (data: IFlightLogTable) => {
        const formData: IFlightLog = transformFlightLogToFormData(data);
        setFlightLogFormData(formData);
        handleModalOpen();
    };

    const handleFlightLogDelete = (data: IFlightLog) => {
        setFlightLogFormData(data);
        setDelOpen(true);
    };

    // Handling Filter changes
    const handleFilterColumns = (index: string, bool: boolean) => {
        const filterOption = filterMultiOptions(filterOptions, index, bool);
        const filteredColumns = flightLogTableColumns.flatMap(x => filterOption.find(z => z.key === x.key)?.checked ? x : [])
        setFilterOptions(filterOption);
        setColumns(index ? filteredColumns : []);
    }
    // to manipulate the flight logs data based on what filter was checked/unchecked.
    const handleMultiSelectChange = (key: string, checked: boolean, title: string) => {
        let filteredTable: IFlightLog[] = [];

        const newList: MultiDropdownSelectKeyValue[] = filterMultiOptions(titleToFilterListMap[title], key, checked);
        const selected: string[] = newList.flatMap(mSelect => mSelect.checked ? mSelect.key : []);
        const keyName = titleToFlightLogKeyMap[title];

        filteredTable = originalFlightLog.reduce((result: IFlightLog[], logs: IFlightLog) => {
            if (title.toLowerCase() === 'am/pm') {
                if (selected.length === titleToFilterListMap[title].length) {
                    return originalFlightLog;
                } else if (selected.length > 0) {
                    if (selected[0].toLowerCase() === 'am') {
                        if (getHours(new Date(logs.takeoffTime)) < 12) {
                            result.push(logs);
                        }
                    } else if (selected[0].toLowerCase() === 'pm') {
                        if (getHours(new Date(logs.takeoffTime)) >= 12) {
                            result.push(logs);
                        }
                    }
                }
            } else {
                if (!logs[keyName as keyof typeof logs] && selected.includes('rest')) {
                    result.push(logs);
                }
                if (selected.includes(logs[keyName as keyof typeof logs]?.toString() ?? '')) {
                    result.push(logs);
                }
            }
            return result;
        }, []);

        setFlightLogList(filteredTable);

        // Setting of the filters list.
        Object.keys(filteredTableObj).forEach(x => {
            if (title !== x) {
                filteredTableObj[x].setter(
                    createMultiSelectFilterList(
                        filteredTable,
                        keyAndTitleMap[filteredTableObj[x].key].key,
                        keyAndTitleMap[filteredTableObj[x].key].title
                    )
                );
            } else {
                filteredTableObj[x].setter(newList);
            }
        });
    }

    const handleResetFilter = () => {
        updateLogsMultiFilter(originalFlightLog);
        setFlightLogList(originalFlightLog);
        setAmpmList(ampm);
    }

    // End of Handle Filter Changes

    const handleToastChanges = (state: boolean) => {
        setToastState({ ...toastState, openCloseState: state })
    };

    const onClickPdfMakeHandler = (type: string) => {
        const contentBody = buildContentBody({
            header: filterOptions.filter(options => options.checked),
            data: transformLogs(listOfFlightLog, aircraftTypes)
        }, true);

        const reportData = buildPdfPayload(selectedDateRange, newReservoir, contentBody);
        if (type === 'pdf') generateReport(reportData);
        else if (type === 'excel') exportExcel(newReservoir, contentBody[0], contentBody.slice(1));
    };

    // selectAll and deselectAll handles the functionality of selecting and deselecting information in the multiple select UI.
    // it receive title from multiple-select component and call getFilterSetter to get the list (options of the multiple select) and its relevant setState
    // to filter and set the state.
    const selectAll = (title: string) => {
        const filter: {
            list: MultiDropdownSelectKeyValue[],
            setter: Dispatch<SetStateAction<MultiDropdownSelectKeyValue[]>>
        } = getFilterSetter(title);
        const newFilterList = filter.list.map(item => ({ key: item.key, value: item.value, checked: true }));
        filter.setter(newFilterList);
        setFlightLogList(originalFlightLog);
        if (title === 'Filter Columns'){
            const filteredColumns = flightLogTableColumns.flatMap(x => newFilterList.find(z => z.key === x.key)?.checked ? x : [])
            setColumns(filteredColumns);
        }
    }

    const deselectAll = (title: string) => {
        const filter: {
            list: MultiDropdownSelectKeyValue[],
            setter: Dispatch<SetStateAction<MultiDropdownSelectKeyValue[]>>
        } = getFilterSetter(title);
        const newFilterList = filter.list.map(item => ({ key: item.key, value: item.value, checked: false }));
        filter.setter(newFilterList);
        setFlightLogList([]);
        if (title === 'Filter Columns'){
            const filteredColumns = flightLogTableColumns.flatMap(x => newFilterList.find(z => z.key === x.key)?.checked ? x : [])
            setColumns(filteredColumns);
        }
    }

    // returns an object with the option list and setState fn.
    const getFilterSetter = (title: string) => {
        const titleToSetterMap: { [key: string]: { list: MultiDropdownSelectKeyValue[], setter: Dispatch<SetStateAction<MultiDropdownSelectKeyValue[]>> } } = {
            "project": { list: projectList, setter: setProjectList },
            "venue": { list: venueList, setter: setVenueList },
            "tail num": { list: tailNumList, setter: setTailNumList },
            "pilot": { list: pilotList, setter: setPilotList },
            "external pilot": { list: extPilotList, setter: setExtPilotList },
            "filter columns": { list: filterOptions, setter: setFilterOptions },
            "am/pm": { list: ampmList, setter: setAmpmList }
        };
        return titleToSetterMap?.[title.toLowerCase()];
    }
    // meant for filters that manipulate the data directly instead of the table
    const multiSelectFilterFn = {
        handleMultiSelectChange,
        selectAll,
        deselectAll
    };
    // meant for filters that manipulate the table directly (column)
    const filterColumnFn = {
        handleFilterColumns,
        selectAll,
        deselectAll
    };
    // End of Filtering select/deselect all functionality
    const logsDataFilter = [
        { key: 'ampmFilter', title: 'AM/PM', item: ampmList, parentCallback: multiSelectFilterFn, data: [], forFilter: true },
        { key: 'projectFilter', title: 'Project', item: projectList, parentCallback: multiSelectFilterFn, data: [], forFilter: true },
        { key: 'venueFilter', title: 'Venue', item: venueList, parentCallback: multiSelectFilterFn, data: [], forFilter: true },
        { key: 'tailNoFilter', title: 'Tail Num', item: tailNumList, parentCallback: multiSelectFilterFn, data: [], forFilter: true },
        { key: 'pilotsFilter', title: 'Pilot', item: pilotList, parentCallback: multiSelectFilterFn, data: [], forFilter: true },
        { key: 'extPilotsFilter', title: 'External Pilot', item: extPilotList, parentCallback: multiSelectFilterFn, data: [], forFilter: true },

    ];

    return isLoading ? (<></>) : (
        <>
            <Grid container mt={1} spacing={2} >
                <Grid item xs>
                <div style={{ overflowX: 'auto' }}>
                <Stack pb={2} direction="row" alignItems="top" spacing={2} style={{ marginTop: '7.5px'}}>
                    <DateRange selectionRange={selectedDateRange} parentCallback={handleDateChange} />
                    {logsDataFilter.map(filter =>
                    <MultipleSelect key={filter.key ?? ''} item={filter.item ?? []} parentCallback={filter.parentCallback ?? null} title={filter.title ?? ''} style={MULTI_SELECT_STYLE} data={filter.data ?? []} forFilter={filter.forFilter ?? false} />
                    )}
                    <Button sx={{ marginLeft: 0 }} variant='text' onClick={handleResetFilter}>Reset Filter</Button>
                </Stack>
                </div>
                </Grid>
            </Grid>
            <Grid ml={1} item xs>
                <Stack pb={2} direction="row" alignItems="center" spacing={2} justifyContent='space-between'>
                    <MultipleSelect key={`filterCol`} item={filterOptions} parentCallback={filterColumnFn} title='Filter Columns' style={MULTI_SELECT_STYLE} data={[]} forFilter={true} />
                    <Box justifyContent={'end'} pr={2}>
                        <Button sx={{ marginInlineEnd: 1 }} variant='contained' onClick={() => { onClickPdfMakeHandler('pdf') }}>Export to PDF</Button>
                        <Button sx={{ marginLeft: 0 }} variant='contained' onClick={() => { onClickPdfMakeHandler('excel') }}>Export to Excel</Button>
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs>
                <DataTable columns={isAuthorised(['FLM_002', 'FLM_003']) ? [...columns, actionColumn] : [...columns]} data={listOfFlightLog} loading={isFetching}></DataTable>
            </Grid>
            <Grid item xs>
                {isAuthorised('FLM_001') ? <Grid item xs>
                    <Button variant='contained' sx={{ marginLeft: 1, bottom: '3.5rem' }} onClick={handleModalOpen}>Add New</Button>
                </Grid> : <></>}
            </Grid>
            <ModalComponent key={`${open}_logModal`} open={open} handleModalClose={handleModalClose} handleModalOnSubmit={handleModalOnSubmit}
                isEdit={isEdit} flightLogFormData={flightLogFormData} constants={constants}></ModalComponent>
            <DelModalComponent key={`${delOpen}_delModal`} open={delOpen} handleModalClose={handleModalClose} handleModalOnSubmit={handleModalOnSubmit}
                idToDelete={flightLogFormData?.jiraId ?? ''}></DelModalComponent>
            {toastState.openCloseState ? <Toastbox openCloseState={true} message={toastState.message} type={toastState.type} duration={toastState.duration} callback={handleToastChanges} /> : <></>}
        </>
    );
}

export default FlightLogs;