import { Ref, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { IAircraftType } from '../_models/flightLog';

interface IDropdownObj {
    disable?: boolean, style: any, item: string[], parentCallback: any,
    title: string, data: string, forFilter: boolean, freeSolo: boolean, transformationList?: IAircraftType
}
interface IValidation { require?: boolean, errors?: string | string[] }

const ComboBox = ({ disable = false, style, item, parentCallback, title, data, require, errors, forFilter, freeSolo, transformationList }: IDropdownObj & IValidation) => {
    const [value, setValue] = useState<string | null>(data);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(data);
        setValue(data);
    }, [data])

    return (
        <FormControl sx={style}>
            <Autocomplete
            getOptionDisabled={(option: string) => (disable || item?.length === 0)}
                autoSelect={true}
                autoHighlight={true}
                freeSolo={freeSolo}
                forcePopupIcon={true}
                id="combo-box"
                options={item.length > 0 ? item : ['No Options Available']}
                value={value}
                onInputChange={(event, newInputValue) => {
                    setValue(newInputValue);
                    setInputValue(newInputValue);
                    parentCallback(newInputValue, title.toLowerCase());
                }}
                inputValue={transformText(transformationList ?? {}, inputValue ?? '')}
                renderOption={(props: any, option: any, state: any) => {
                    return <li {...props}>{title.toLowerCase() === 'aircraft type' ? transformText(transformationList ?? {}, option) : option}</li>
                }}
                renderInput={(params) => {
                    return <TextField {...params} label={title} required={require} 
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder={title} />
                }}
            />
        </FormControl>

    );
}

function transformText(list: IAircraftType, option: string) {
    return list && Object.keys(list).length > 0 ? (list[option] ?? '') : option
}

export default ComboBox;