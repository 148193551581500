import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import ComboBox from '../../_components/combobox';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { ConstantService } from '../../_services/constant.service';
import { registerUser, createUserCognito } from '../../_services/user.service';
import { Role, ROLE_MODEL, User } from '../../_models/user';
import Toastbox from '../../_components/toastbox';
import { TOASTBOX } from '../../_models/toastbox';
import JiraService from '../../_services/jira.service';
import { BackdropComponent } from '../../_components/backdrop';
import { useUser } from '../../_contexts/UserContext';

let roleData: ROLE_MODEL[] = [];
const BASE_USER: User = {
    username: '',
    password: '',
    role: '',
    firstName: '',
    lastName: '',
    email: '',
};

const BASE_TAST: TOASTBOX = {
    message: '',
    duration: 0,
    openCloseState: false,
    type: 'success',
    callback: (state: boolean) => false
};

function updateRef(ref: React.RefObject<any>, value: string) {
    if (ref && ref.current) ref.current.value = value;
}

function resetRef(ref: Array<React.MutableRefObject<HTMLInputElement | null>> | React.MutableRefObject<HTMLInputElement | null>) {
    if (Array.isArray(ref)) {
        ref.forEach(r => {
            if (r?.current) r.current.value = '';
        });
    } else {
        if (ref?.current) ref.current.value = '';
    }

}

function transformRoleApiDataToRoleList(roles: ROLE_MODEL[], company: string): string[] {
    return roles?.reduce((acc: string[], cur: ROLE_MODEL) => {
        const roleName = `${cur.roleName[0].toUpperCase()}${cur.roleName.slice(1).toLowerCase()}`;
        if (company?.toLowerCase() === 'none') {
            acc = acc?.includes(roleName) ? acc : [...acc, roleName];
        } else {
            if (cur.company?.toLowerCase() === company.toLowerCase()) {
                acc = acc?.includes(roleName) ? acc : [...acc, roleName];
            }
        }
        return acc;
    }, []);
}

function manipulateProject(projects: []): string[] {
    return [...['None'], ...projects?.map((project: { key: string, value: string }) => project.value)];
}

const RegistrationForm = () => {
    const [toast, setToast] = useState<TOASTBOX>(BASE_TAST);
    const [roleList, setRoleList] = useState<string[]>([]);
    const [role, setRole] = useState<Role>('');
    const [projectList, setProjectList] = useState<string[]>(['None', 'SKYPORTS']);
    const [companyList, setCompanyList] = useState<string[]>([]);
    const [project, setProject] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [tempPassChecked, setTempPassChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    let usernameRef = useRef<HTMLInputElement | null>(null);
    let passwordRef = useRef<HTMLInputElement | null>(null);
    let emailRef = useRef<HTMLInputElement | null>(null);
    let firstNameRef = useRef<HTMLInputElement | null>(null);
    let lastNameRef = useRef<HTMLInputElement | null>(null);
    // const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{6,256}$/;
    // Context
    const { data } = useUser();

    useEffect(function onFirstLoad() {
        const getConstants = async () => {
            roleData = await ConstantService.getRole('');
            const company = roleData?.reduce((acc: string[], cur: ROLE_MODEL) => {
                if (!cur.company) {
                    return acc.includes('None') ? acc : [...acc, 'None'];
                }
                const companyName = `${cur.company[0].toUpperCase()}${cur.company.slice(1).toLowerCase()}`;
                return acc?.includes(companyName) ? acc : [...acc, companyName];
            }, []);
            setCompanyList(company);
            setLoading(false);
        };
        getConstants();
    }, [])

    const handleToastChanges = (state: boolean) => setToast({ ...toast, openCloseState: state })

    const handleOnClear = () => {
        resetRef([usernameRef, emailRef, firstNameRef, lastNameRef]);
        setRole('');
        setProject('');
    };

    const handleRoleChange = (value: Role, title: string) => {
        setRole(value);
    };

    const handleProjectChange = (value: string, title: string) => {
        setProject(value);
    }

    const handleTempPassChecked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setTempPassChecked(checked);
    }

    const handleCompanyChange = (value: string) => {
        setCompany(value);
        const roles = transformRoleApiDataToRoleList(roleData, value);
        setRoleList(roles);
    }

    const handleOnSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const registerPayload: User = {
            username: usernameRef.current?.value ?? '',
            firstName: firstNameRef.current?.value ?? '',
            lastName: lastNameRef.current?.value ?? '',
            email: emailRef.current?.value ?? '',
            role: (role?.toLowerCase() ?? '') as keyof Role ?? '',
            project: project.toLowerCase() !== 'none' ? project.toLowerCase() : '',
            tempPass: tempPassChecked,
            company: company.toLowerCase() !== 'none' ? company.toLowerCase() : '',
        };
        try {
            const cognitoResult = await createUserCognito(registerPayload);
            if (cognitoResult?.status === 200) {
                const success = await registerUser({ ...registerPayload });
                setToast({ ...toast, openCloseState: true, duration: 5000, message: `Account has been successfully created. An notification email has been sent to the user's registered email.`, type: 'success' });
                handleOnClear();
            } else {
                setToast({ ...toast, openCloseState: true, duration: 5000, message: `${cognitoResult?.data?.name}, kindly contact the Administrator with the error for assistance.`, type: 'error' });
            }
        } catch (error: any) {
            setToast({ ...toast, openCloseState: true, duration: 5000, message: `${error.response.data.name}`, type: 'error' });
        }
    };

    const labelInputProps = { shrink: true };
    return (
        <>
            <Box component='form' onSubmit={handleOnSubmit}>
                <FormControl sx={{ width: '100%', margin: '0 auto', textAlign: 'center' }} >
                    <Grid container spacing={3} sx={{ width: '500px', margin: '0 auto' }}>
                        <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                            <TextField InputLabelProps={labelInputProps} required sx={{ width: '100%' }} inputRef={usernameRef} id='username' label='Username' placeholder='Username' defaultValue={usernameRef.current?.value ?? ''} />
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                            <TextField InputLabelProps={labelInputProps} required inputRef={firstNameRef} id='firstName' label='First Name' placeholder='First Name' defaultValue={firstNameRef.current?.value ?? ''} />
                            <TextField InputLabelProps={labelInputProps} required inputRef={lastNameRef} id='lastName' label='Last Name' placeholder='Last Name' defaultValue={lastNameRef.current?.value ?? ''} />
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                            <TextField InputLabelProps={labelInputProps} required inputRef={emailRef} sx={{ width: '100%' }} id='email' label='Email' placeholder='Email' type='email' defaultValue={emailRef.current?.value ?? ''} />
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                        <ComboBox require={true} key={`company`} style={{ mr: 2, width: '24ch' }} item={companyList ?? []}
                                parentCallback={handleCompanyChange} title='Company' data={company ?? ''} forFilter={false} freeSolo={true} />
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                            <ComboBox require={true} key={`roles`} style={{ mr: 2, width: '24ch' }} item={roleList ?? []}
                                parentCallback={handleRoleChange} title='Role' data={role ?? ''} forFilter={false} freeSolo={true} />
                            <ComboBox require={true} key={`projects`} style={{ mr: 2, width: '24ch' }} item={projectList ?? []}
                                parentCallback={handleProjectChange} title='Project' data={project ?? ''} forFilter={false} freeSolo={true} />
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={tempPassChecked}
                                        onChange={handleTempPassChecked}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} label="Temporary Password" />
                            </FormGroup>

                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='space-between'>
                            <Button variant='outlined' onClick={handleOnClear} sx={{ textAlign: 'left' }} type='reset'>
                                Clear
                            </Button>
                            <Button variant='contained' className='float-right' type='submit'>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </Box>
            <BackdropComponent submitting={loading} />
            {toast.openCloseState ? <Toastbox openCloseState={true} message={toast.message} type={toast.type} duration={toast.duration} callback={handleToastChanges} /> : <></>}
        </>
    );
};

export default RegistrationForm;