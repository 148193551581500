import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { Button, FormHelperText, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import ComboBox from '../../_components/combobox';
import { useState } from 'react';
import { ConstantService } from '../../_services/constant.service';
import { registerUser, createUserCognito } from '../../_services/user.service';
import { NewPasswordType, Role, User } from '../../_models/user';
import Toastbox from '../../_components/toastbox';
import { TOASTBOX } from '../../_models/toastbox';
import { VisibilityOff, Visibility } from '@mui/icons-material';



const labelInputProps = { shrink: true };

export const ResetPasswordForm = ({onSubmit, onClear, disabled = false}: any) => {
    let [newPassword, setNewPassword] = useState<NewPasswordType>({ password: '', repeatPassword: '' });
    let [passwordShown, setPasswordShown] = useState(false);
    const passwordRequirementArr = [
        '8-character minimum length',
        'Contains at least 1 number',
        'Contains at least 1 lowercase letter',
        'Contains at least 1 uppercase letter',
        'Contains at least 1 special character',
        'New password must differ from your previous password'
      ];
      
    const handleOnSubmit = async (event: any) => {
        await setNewPassword((prevPassword) => ({ ...prevPassword }));
        onSubmit(event, newPassword);
    };
      

    function handleOnClear() {
        onClear(false);
    }

    const handlePasswordChange = (password: string, inputName: string) => {
        if (inputName === 'newPassword') {
            setNewPassword({ ...newPassword, password });
        }
        else if (inputName === 'repeatPassword') {
            setNewPassword({ ...newPassword, repeatPassword: password });
        }
    };

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
      };
    
      const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };

    return (
        <>
            <Box component='form' onSubmit={handleOnSubmit}>
            <Typography>
                Requirements:
                </Typography>
                {passwordRequirementArr.map( (requirement, i) => <Typography key={i} component={'li'} sx={{listStyleType: 'square'}}>{requirement}</Typography>)}
                <FormControl sx={{ width: '100%', margin: '0 auto', textAlign: 'center' }} >
                    <Grid container spacing={2} sx={{ width: '100%', margin: '0 auto' }}>
                        <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                            <TextField 
                            disabled={disabled}
                            autoFocus={true}
                            InputLabelProps={labelInputProps} 
                            type={passwordShown ? 'text' : 'password'}
                            required sx={{ width: '100%' }} 
                            id='newPassword' 
                            label='New Password' 
                            placeholder='Password' 
                            defaultValue={newPassword.password ?? ''} 
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handlePasswordChange(event.target.value, 'newPassword');}
                            }
                            InputProps={{
                                endAdornment:  
                                    <InputAdornment position="start">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {passwordShown ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                            }}
                              />
                            {/* <Popover content={content} trigger="focus" placement="right">
                                <TextField required inputRef={passwordRef} id='password' label='Password' placeholder='Password' type='password'
                                    inputProps={{ minLength: '8' }} defaultValue={passwordRef.current?.value ?? 'dr0net@STEA'}/>
                            </Popover> */}
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                            <TextField 
                            disabled={disabled}
                            InputLabelProps={labelInputProps} 
                            type={passwordShown ? 'text' : 'password'}
                            required sx={{ width: '100%' }} 
                            id='repeatPassword' 
                            label='Repeat Password' 
                            placeholder='Password' 
                            defaultValue={newPassword.repeatPassword ?? ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handlePasswordChange(event.target.value, 'repeatPassword');}
                            } 
                            InputProps={{
                                endAdornment:  
                                    <InputAdornment position="start">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {passwordShown ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                            }}/>
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='space-between'>
                            <Button variant='outlined' onClick={handleOnClear} sx={{ textAlign: 'left' }} type='reset'>
                                Cancel
                            </Button>
                            <Button variant='contained' className='float-right' type='submit'>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </FormControl>
            </Box>
        </>
    );
};