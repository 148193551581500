import {
    useState,
    useEffect,
    useRef
} from "react";
import {
    download,
    downloadDocumentationByProject,
    getAll,
    upload,
    deleteObject
} from "../_services/documentation.service";
import { S3_Object_Content } from "../_models/documentation";
// UI Imports
import {
    Button,
    Table,
    Select
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { UploadOutlined } from '@ant-design/icons';
import { useUser } from "../_contexts/UserContext";
import { isAuthorised } from "../_services/permission.service";
// End of UI Import

interface DataType {
    key: React.Key;
    fileName: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'File Name',
        dataIndex: 'fileName',
        width: '80%',
    },
    {
        title: 'Upload Date and Time',
        dataIndex: 'uploadDatetime',
    }
];

let startAfter = '';

/**
 * 
 * @param doc Contents in list of documentation retrieved from S3
 * @param startAfter the condition used in retrieving the object from S3
 * @returns an array in DataType[] type for antd Table consumption
 */
function transformDocListToTableData(doc: S3_Object_Content[], startAfter: string): DataType[] {
    return doc.reduce((acc, cur) => ([...acc, {
        key: cur.Key?.replace(startAfter, '') ?? '',
        entityTag: cur.ETag ?? '',
        fileName: cur.Key?.replace(startAfter, '') ?? '',
        uploadDatetime: cur.LastModified ? new Date(cur.LastModified).toLocaleString('en-SG', {
            timeZone: 'Asia/Singapore',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        }) : ''
    }
    ]), [] as DataType[]);
}

const Documentation = () => {
    const [documentationList, setDocumentationList] = useState<S3_Object_Content[]>([]);
    const [documentation, setDocumentation] = useState<DataType[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);
    const { data } = useUser();
    const hasSelected = selectedRowKeys.length > 0;
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [triggerEffect, setTriggerEffect] = useState(false);
    const [enableUploadButton, setEnableUploadButton] = useState(true);
    const [selectedUploadFile, setSelectedUploadFile] = useState<File>();
    const [selectedUploadBlob, setSelectedUploadBlob] = useState<Blob>();
    const [project, setProject] = useState<string>('');
    const projectList = [
        { value: 'none', label: 'None' },
        { value: 'skyports', label: 'Skyports' }
    ]
    useEffect(() => {
        // retrieve documentation objects from S3
        const retrieveDocumentations = async() => {
            const docResp = await getAll();
            if (docResp.data.$metadata.httpStatusCode === 200) {
                setDocumentationList(docResp.data.Contents);
                startAfter = docResp.data.StartAfter ?? '';
                // transform the documentation list into usable antd table data
                const tableData = transformDocListToTableData(docResp.data.Contents, startAfter);
                setDocumentation(tableData);
            }
        }
        const retrieveProjectDocumentations = async() => {
            const projDocResp = await downloadDocumentationByProject(data?.['custom:project']);
            if (projDocResp.data.$metadata.httpStatusCode === 200) {
                setDocumentationList(projDocResp.data.Contents);
                startAfter = projDocResp.data.StartAfter ?? '';
                // transform the documentation list into usable antd table data
                const tableData = transformDocListToTableData(projDocResp.data.Contents, startAfter);
                setDocumentation(tableData);
            }
        }

        if (data?.['custom:project']) {
            retrieveProjectDocumentations();
        } else {
            retrieveDocumentations();
        }
        setTriggerEffect(false);
    }, [triggerEffect]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const handleDownload = async () => {
        const selected = selectedRowKeys.flatMap(x => ({ Key: `${startAfter}${documentation.find(z => x === z.key)?.fileName}` }));
        for (const payload of selected) {
            const res = await download(payload);
            if (res.status === 200) {
                const a = document.createElement('a');
                const blob = new Blob([new Uint8Array(Object.values(res.data.Body))], { type: res.data.ContentType });
                const url = window.URL.createObjectURL(blob);
                a.setAttribute('download', `${res.data.fileName.replace(startAfter, '')}`);
                a.href = url;
                a.click();
                a.remove();
            }
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleFileChange = async (e: any) => {
        await setSelectedUploadFile(e.target.files[0]);
        console.log(selectedUploadFile);
        await handleBlob(e.target.files[0]);
        setEnableUploadButton(false);
    }

    const handleBlob = (file: any) => {
        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {

                const arrayBuffer = event.target?.result;

                if (arrayBuffer instanceof ArrayBuffer) {
                    // Create a Blob from the file
                    const blob = new Blob([file], { type: file.type });
                    setSelectedUploadBlob(blob);
                } else if (typeof arrayBuffer === 'string') {
                    console.log('Received string data:', arrayBuffer);
                } else {
                    console.log('Invalid data type:', typeof arrayBuffer);
                }
            };
            reader.readAsArrayBuffer(file);
        } else {
            console.log('No file selected for upload');
        }
    }

    const handleUpload = async () => {
        handleBlob(selectedUploadFile);
        if ((selectedUploadBlob) && (selectedUploadFile)) {
            try{
                // Upload file to S3
                const res = await upload(selectedUploadBlob, selectedUploadFile.name, project);
                console.log(res.status);
                
            } catch(error) {
                console.log("File upload error: ", error);
            }
        }
        setEnableUploadButton(true);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setTriggerEffect(true);
    };

    const handleDelete = async () => {
        const selected = selectedRowKeys.flatMap(x => ({ Key: `${startAfter}${documentation.find(z => x === z.key)?.fileName}` }));
        for (const payload of selected) {
            const res = await deleteObject(payload);
        }
        selectedRowKeys.length = 0;
        setTriggerEffect(true);
    }

    const handleProjectChange = (value: string) => {
        setProject(value);
    }

    return (
        <div style={{ marginTop: '1em' }}>
            <div>
                <Button type="primary"
                    style={{ marginLeft: '1em', width: '95px' }}
                    onClick={handleDownload}
                    disabled={!hasSelected}
                    loading={loading}>
                    Download
                </Button>
                {isAuthorised('FLM_007') ? <Button type="primary"
                    style={{ marginLeft: '1em', width: '95px' }}
                    onClick={handleDelete}
                    disabled={!hasSelected}
                    loading={loading}
                    danger>
                    Delete
                </Button> : <></>}
                <span style={{ marginLeft: 8 }}>
                {hasSelected ? `Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? 'item' : 'items'}` : ''}
                </span>
            </div>
            <Table rowSelection={rowSelection} columns={columns} dataSource={documentation}/>
            {isAuthorised('FLM_007') ? <div>
                <input type="file" onChange={handleFileChange} ref={fileInputRef} style={{ marginLeft: '1.075em' }}/>
                <div style={{ marginLeft: '1em' }}>
                    <Select placeholder="Select Project Type"
                        style={{ width: 160 }}
                        onChange={ handleProjectChange }
                        options={ projectList }/>
                    <Button icon={<UploadOutlined/>}
                        style={{ marginLeft: '1em' }}
                        disabled = { enableUploadButton }
                        onClick={ handleUpload }>
                        Click to Upload
                    </Button>
                </div>
                <div style={{ height: '1em' }}/>
            </div> : <></>}
        </div>
    )
}

export default Documentation;