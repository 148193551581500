import './navbar.css';
import { useEffect, useState } from 'react';
import { getUserToken, isAuthorised } from '../_services/permission.service';
import { Col, Menu, Row, Typography, Dropdown, Button } from 'antd';
import Modal from "@mui/material/Modal";
import type { MenuProps } from 'antd';
import { MODULE_ID } from '../_services/constant.service';
import { useUser } from '../_contexts/UserContext';
import LinkComponent from '../_components/link';
// Icons
import st_logo from '../assets/icons/st_logo.png';
import documentation_icon from '../assets/icons/Documentation.svg';
import flight_logs_icon from '../assets/icons/Flight-Logs.svg';
import home_icon from '../assets/icons/Home.svg';
import sqn_icon from '../assets/icons/SQN_1.svg';
import battery_icon from '../assets/icons/Battery.svg'
import maintenance_icon from '../assets/icons/Maintenance_1.svg';
import { MenuOutlined } from '@ant-design/icons';
import { LoginOutlined, UserOutlined } from '@ant-design/icons/lib/icons';
import Login from '../login-page/login';
import { stopTimeout } from '../_services/user.service';
import Layout from 'antd/lib/layout/layout';
import { usePermission } from '../_contexts/PermissionContext';
import { SubMenuType } from 'antd/es/menu/hooks/useItems';
import { useLocation } from 'react-router-dom';

const { Text, Title } = Typography;

type MenuItem = Required<MenuProps>['items'][number];
type MenuOnClick = { domEvent: any, key: string, keyPath: string[], item: any };

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const colStyle = {height: '64px'};

const Icon = ({ src }: { src: string }) => {
    return (
        <>
            <img style={{ maxHeight: '18px', maxWidth: '18px', marginRight: '0.5em' }} src={src} />
        </>
    )
};

const mainNavItems: MenuItem[] = [
    getItem(
        <LinkComponent
            id={MODULE_ID.MODULE_LEVEL.DASHBOARD}
            to='/dashboard'
            text='Dashboard' />,
        MODULE_ID.MODULE_LEVEL.DASHBOARD,
        <Icon src={home_icon} />
    ),
    getItem(
        <LinkComponent
            id={MODULE_ID.MODULE_LEVEL.FLIGHT_LOGS}
            to='/flightLogs'
            text='Flight Logs' />,
        MODULE_ID.MODULE_LEVEL.FLIGHT_LOGS,
        <Icon src={flight_logs_icon} />),
    getItem(
        <LinkComponent
            id={MODULE_ID.MODULE_LEVEL.MAINTENANCE}
            to='/maintenance'
            text='Battery Diagnostics' />,
        MODULE_ID.MODULE_LEVEL.MAINTENANCE,
        <Icon src={battery_icon} />),
    // getItem(
    //     <LinkComponent
    //         id={MODULE_ID.MODULE_LEVEL.SQN}
    //         to='/sqn'
    //         text='SQN' />,
    //     MODULE_ID.MODULE_LEVEL.SQN,
    //     <Icon src={sqn_icon} />),
    getItem(
        <LinkComponent
            id={MODULE_ID.MODULE_LEVEL.DOCUMENTATION}
            to='/documentation'
            text='Documentation' />,
        MODULE_ID.MODULE_LEVEL.DOCUMENTATION,
        <Icon src={documentation_icon} />),
];


// Menu Items for Account Dropdown
const accountMenuItems: Readonly<MenuItem[]> = [
    getItem('', null, <UserOutlined />,
        [
            getItem(
                <LinkComponent
                    id={MODULE_ID.FUNCTION_LEVEL.ADMIN_CONSOLE}
                    to='/admin_console/user_management'
                    text='Admin Console' />,
                MODULE_ID.FUNCTION_LEVEL.ADMIN_CONSOLE),
            getItem(
                <LinkComponent
                    id='logout'
                    to='/'
                    text='Logout' />,
                'logout')
        ]
    )
] as const;

const UserDiv = (props: { username: string, onClick: ($event: MenuOnClick) => void }) => {
    const [itemState, setItemState] = useState<MenuItem[]>([]);
    const handleAccountMenuClick = (event: MenuOnClick) => {
        props.onClick(event);
    };

    useEffect(() => {
        if (itemState.length === 0) {
            const item = accountMenuItems.reduce((acc: any, cur: any) => {
                let x = { ...cur };
                x.children = x.children.filter((z: SubMenuType) => {
                    if (!z.key || z.key === 'logout') {
                        return z;
                    }

                    if (isAuthorised(z.key as string)) {
                        return z;
                    }
                });
                return [...acc, x];
            }, []);
            setItemState([...item]);
        }
    }, [itemState])
    return (
        <Row>
            <Col>
                <Text>Welcome {props.username}</Text>
            </Col>
            <Col>
                <Menu
                    onClick={handleAccountMenuClick}
                    theme="light" mode="horizontal"
                    items={itemState}
                    subMenuCloseDelay={0.25}
                />
            </Col>

        </Row>
    );
}

const UserDiv2 = (props: { username: string, onClick: ($event: MenuOnClick) => void }) => {
    const [visible, setVisible] = useState(false);
    const [itemState, setItemState] = useState<MenuItem[]>([]);

    const handleAccountMenuClick = (event: MenuOnClick) => {
        props.onClick(event);
        setVisible(false);
    };

    useEffect(() => {
        if (itemState.length === 0) {
            const item = accountMenuItems.reduce((acc: any, cur: any) => {
                let x = { ...cur };
                x.children = x.children.filter((z: SubMenuType) => {
                    if (!z.key || z.key === 'logout') {
                        return z;
                    }

                    if (isAuthorised(z.key as string)) {
                        return z;
                    }
                });
                return [...acc, x];
            }, []);
            setItemState([...item]);
        }
    }, [itemState]);

    const handleVisibleChange = (flag: boolean) => {
        setVisible(flag);
    };

    const filteredMainNavItems: MenuItem[] = mainNavItems.filter(
        (item) => isAuthorised(item?.key as string)
    );    
    
    const allMenuItems: MenuItem[] = [...filteredMainNavItems];

    if (itemState && itemState[0] && 'children' in itemState[0] && Array.isArray(itemState[0].children)) {
        allMenuItems.push(...itemState[0].children);
    }

    const menu = (
        <Menu onClick={handleAccountMenuClick} theme="light" subMenuCloseDelay={0.25}>
            {allMenuItems.map(item => (
                item && 'label' in item && 'icon' in item ? (
                    <Menu.Item key={item.key} icon={item.icon}>
                        {item.label}
                    </Menu.Item>
                ) : (
                    item && 'label' in item ? (
                        <Menu.Item key={item.key}>
                            {item.label}
                        </Menu.Item>
                    ) : null
                )
            ))}
        </Menu>
    );

    return (
        <Row>
            <Col>
                <Dropdown overlay={menu} open={visible} onOpenChange={handleVisibleChange}>
                    <Button type="text" icon={<MenuOutlined />}/>
                </Dropdown>
            </Col>
        </Row>
    );
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 100,
    maxWidth: 800,
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    p: 4,
};


const LoginModal = ({ open, handleClose, handleSubmit }: any) => {
    // let [toastState, setToastState] = useState<TOASTBOX>({ openCloseState: false, message: '', type: 'success', duration: 5000, callback: () => false });
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Layout style={style}>
                    <Login handleClose={handleClose}></Login>
                </Layout>
            </Modal>
        </>
    );
}

const NavBar = () => {
    const [loginUser, setLoginUser] = useState<string>('');
    const [openLogin, setOpenLogin] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { permission } = usePermission();
    const { data, logout } = useUser();
    useEffect(() => {
        setLoginUser(data?.name ?? '');
    }, [data])

    useEffect(() => {
    }, [permission])

    const handleLoginClick = () => {
        setOpenLogin(true);
    }

    const handleLoginClose = () => {
        setOpenLogin(false);
    }

    const updateWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowWidth);
        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        };
    }, []);

    const handleUserMenuClick = ($event: MenuOnClick) => {
        if ($event.key === 'logout') {
            logout();
            stopTimeout();
        }
    }

    const HeaderLink = () => {
        return (
          getUserToken()? <LinkComponent id='st_logo' to='/' image={{ src: st_logo }}/>:
          <img className="logo" src={st_logo}/>
        );
    }

    // To handle navbar menu active state
    const pathToMenuKey: { [key: string]: string} = {
        '/dashboard': MODULE_ID.MODULE_LEVEL.DASHBOARD,
        '/flightLogs': MODULE_ID.MODULE_LEVEL.FLIGHT_LOGS,
        '/maintenance': MODULE_ID.MODULE_LEVEL.MAINTENANCE,
        '/sqn': MODULE_ID.MODULE_LEVEL.SQN,
        '/documentation': MODULE_ID.MODULE_LEVEL.DOCUMENTATION,
    };
    let location = useLocation();
    return (
        <Row style={{height: '64px'}}>
            <Col style={colStyle}>
                <HeaderLink/>
            </Col>
            <Col flex={6}>
                {getUserToken() && windowWidth > 1100 ? ( // Check window width
                    <Menu
                        style={{ width: '100%' }}
                        theme="light"
                        mode="horizontal"
                        items={mainNavItems.map(
                            (item) => (isAuthorised(item?.key as string) ? item : null)
                        )}
                        selectedKeys={[pathToMenuKey[location.pathname]]}
                    />
                ) : (
                    <></>
                )}
            </Col>
            <Col>
                {getUserToken() ? ( windowWidth > 1100 ? (
                    <UserDiv onClick={handleUserMenuClick} username={loginUser} />) : (
                    <UserDiv2 onClick={handleUserMenuClick} username={loginUser} />)
                ): (<>
                        {windowWidth > 350 && <Text>Login</Text>}
                        <Menu onClick={handleLoginClick} style={{ float: 'right' }} theme="light" mode="horizontal" items={[getItem('', 'login', <LoginOutlined />)]}/>
                    </>
                )}
            </Col>
            <LoginModal open={openLogin} handleClose={handleLoginClose} ></LoginModal>
        </Row>
    )
}

export default NavBar;