import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IDropdownObj { disable: boolean, style: any, item: string[], parentCallback: any, title: string, data: string[], forFilter: boolean, freeSolo: boolean }
interface IValidation { require?: boolean, errors?: string | string[] }

const MultiComboBox = ({ disable, style, item, parentCallback, title, data, require, errors, forFilter, freeSolo }: IDropdownObj & IValidation) => {
    const [value, setValue] = useState<string[]>(data?.length > 0 ? data : []);
    return (
        <FormControl sx={style}>
            <Autocomplete
            key={`${title}_key_select`}
            freeSolo={freeSolo}
            forcePopupIcon={true}
            disableClearable={true}
                multiple
                limitTags={1}
                id="multi-checkbox-combo-box"
                options={item}
                disableCloseOnSelect
                getOptionLabel={(option: string) => option}
                onChange={(event: any, newValue: string[], reason: string) => {
                    setValue(newValue);
                    parentCallback(newValue, reason, title.toLowerCase());
                  }}
                value={value}
                getOptionDisabled={(option) => disable ? value.length === 3 && !value.includes(option) : false}
                getLimitTagsText={(more) => `+${more} ${more >= 1 ? 'others' : 'other'}`}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label={`${title}`}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    placeholder={value?.length === 0 ? title : ''} />
                )}
            />
        </FormControl>
    );
}

export default MultiComboBox;