import React, { useRef, useState, useEffect } from 'react';
import './login.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Link } from '@mui/material';
import { environment } from '../environment';
import { captchaTokenValidation } from '../_services/token.service';
import { initiateAuthCognito, 
  associateSoftwareTokenCognito, 
  verifySoftwareTokenCognito, 
  setMfaCognito, 
  responseToAuthChallengeCognito, 
  getUserCognitoAdmin, 
  forgetPasswordCognito, 
  confirmForgetPasswordCognito,
  getPasswordHistory,
  startTimeout } from '../_services/user.service';
import { AxiosResponse } from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ValidatorComponent from '../_components/validator';
import { InitiateAuthModel } from "../_models/cognito";
import Modal from "@mui/material/Modal";
// import Typography from "@mui/material/Typography";
import { BackdropComponent } from "../_components/backdrop";
import { UserAttributes, NewPasswordType } from '../_models/user';
import { ConstantsEnum } from '../_services/constant.service';
import { ResetPasswordForm } from '../shared/reset_password/password_form';
import Toastbox from '../_components/toastbox';
import { TOASTBOX } from '../_models/toastbox';
import { UtilService } from '../_services/utils.service';
import { Row, Typography } from 'antd';
import { useUser } from '../_contexts/UserContext';
import { usePermission } from '../_contexts/PermissionContext';
import { getUserPermission } from '../_services/permission.service';

let userAttributes: {[key: string]: string | number};

const labelInputProps = { shrink: true };

const { Text, Title } = Typography;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ForgetPasswordModal = ({ open, handleModalClose, handleModalOnSubmit }: any) => {
  let [username, setUsername] = useState<string>('');
  let [code, setCode] = useState<string>('');
  let [disabled, setDisabled] = useState(true);
  let [enableError, setEnableError] = useState(false);
  let [disableButton, setDisableButton] = useState(true);
  let [toastState, setToastState] = useState<TOASTBOX>({ openCloseState: false, message: '', type: 'success', duration: 5000, callback: () => false });
  const onSubmit = async (event: any, password: string) => {
    handleModalOnSubmit(event, username, code, password);
  };

  useEffect(() => {
    if (!open) {
      setDisabled(true);
      setDisableButton(true);
      setEnableError(false);
    }
  }, [open]);

  async function sendOtp() {
    try {
      const userData = await getUserCognitoAdmin(username);
      if ((!userData.data.Enabled) || (userData.data.UserStatus === "FORCE_CHANGE_PASSWORD")){
        setDisableButton(true); 
        setEnableError(true);          
        return;
      }
      const sendOtpRes = await forgetPasswordCognito({ username });
      const CODE_SUCCESS_MSG = `Confirmation Code has been sent to the email registered with the username.`;
      if (sendOtpRes?.status === 200) {
        setDisabled(false);
        setToastState({ ...toastState, openCloseState: true, duration: 5000, message: CODE_SUCCESS_MSG, type: 'success' });
      }
    } catch (error: any) {
      setToastState({ ...toastState, openCloseState: true, duration: 5000, message: UtilService.generateErrorToastMessage(error), type: 'error' });
      setDisableButton(true); 
      setEnableError(true);
    }
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setUsername(inputValue);
    setDisableButton(inputValue === '');
  };

  return (
    <>
      <Modal
        sx={{ minWidth:'25em', maxWidth: '75em', width:'100%', margin: '0 auto', alignItems:'left' }}
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Title id="modal-modal-title" level={2}>
            Forget Password
          </Title>
          <Grid container spacing={2} sx={{ width: '100%', margin: '0 auto' }}>
            <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
              <TextField
                autoFocus={true}
                InputLabelProps={labelInputProps}
                required sx={{ width: '100%' }}
                id='username'
                label='Username'
                placeholder='Username'
                onChange={handleUsernameChange} />
            </Grid>
            <Grid container spacing={2} sx={{ width: '100%', margin: '0 auto' }}>
              <Grid item xs={6} display='flex' justifyContent='space-between' alignItems='center'>
                <TextField
                  disabled={disabled}
                  InputLabelProps={labelInputProps}
                  required sx={{ width: '100%' }}
                  id='confirmationCode'
                  label='Confirmation Code'
                  placeholder='Code'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCode(event.target.value)} />

              </Grid>
              <Grid item xs={4} alignItems='center'>
                <Button sx={{ marginTop: '8px' }} variant='outlined' disabled={disableButton} onClick={sendOtp}>
                  Send OTP
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Title id="modal-modal-title" level={5} style={{ marginLeft: '18px', textAlign: 'center', color: 'red', display: enableError ? 'inline' : 'none'}}>
            OTP request disabled. Kindly contact the Administrator for assistance.
          </Title>
          <Grid container>
            <Grid item mt={1.5}>
              <ResetPasswordForm onSubmit={onSubmit} onClear={handleModalClose} disabled={disabled} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {toastState.openCloseState ? <Toastbox openCloseState={true} message={toastState.message} type={toastState.type} duration={toastState.duration} callback={(state: boolean) => setToastState({ ...toastState, openCloseState: state })} /> : <></>}
    </>
  )
};

/**
 * A modal that contains password form
 * @param param0 open - show/hide the modal, handleModalClose - callback fn to close the modal, handleModalOnSubmit - callback fn to handle submit flow.
 * @returns 
 */
const PasswordModal = ({ open, handleModalClose, handleModalOnSubmit }: any) => {
  let [toastState, setToastState] = useState<TOASTBOX>({ openCloseState: false, message: '', type: 'success', duration: 5000, callback: () => false });
  const onSubmit = async (event: any, password: string) => {
      handleModalOnSubmit(event, password);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Title id="modal-modal-title" level={2}>
            Change New Password
          </Title>
          <ResetPasswordForm onSubmit={onSubmit} onClear={handleModalClose} />
        </Box>
      </Modal>
      {toastState.openCloseState ? <Toastbox openCloseState={true} message={toastState.message} type={toastState.type} duration={toastState.duration} callback={(state: boolean) => setToastState({ ...toastState, openCloseState: state })} /> : <></>}
    </>
  )
};

const Login = (props: { handleClose: () => void}) => {
  const [username, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordShown, setPasswordShown] = React.useState(false);
  let token = "";
  let msg: AxiosResponse<any, any>;
  const recaptcha_site_key = environment.reCaptchaSiteKey;
  const navigate = useNavigate();
  const [errorMsg, seterrorMsg] = useState('');
  // cognito enhancement
  const [secretCode, setSecretCode] = useState('');
  const accessToken = useRef('');
  const sessionKey = useRef('');
  const challengeName = useRef('');
  const [validatorOpen, setValidatorOpen] = useState(false);
  const [newPasswordOpen, setNewPasswordOpen] = useState(false);
  let [forgetPasswordModalState, setForgetPasswordModalState] = useState(false);
  let [submitting, setSubmitting] = useState(false);
  let [toastState, setToastState] = useState<TOASTBOX>({ openCloseState: false, message: '', type: 'success', duration: 5000, callback: () => false });
  let [challengeObj, setChallengeObj] = useState({
    sessionKey: sessionKey.current,
    username,
    challengeName: challengeName.current
  });

  //Contexts
  const { data, set} = useUser();
  const { permission, setPermission } = usePermission();

  function onAuthChallenge(challengeName: string) {
    setSecretCode('');
    switch (challengeName) {
      case ConstantsEnum.NEW_PASSWORD: {
        setNewPasswordOpen(true);
        break;
      }
      case ConstantsEnum.MFA_TOKEN: {
        setValidatorOpen(true);
        break;
      }
      default: {
        return null;
      }
    }
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  window.onload = function () {
    removeRecaptchaFromLS();
  }

  async function getToken(value: any) {
    token = value;
    msg = await captchaTokenValidation(token);
    if (localStorage.getItem('_grecaptcha') != null) {
      console.log("Captcha Validation Done!");
    }
  }

  function recaptchaExpired() {
    removeRecaptchaFromLS();
  }

  function removeRecaptchaFromLS() {
    localStorage.removeItem('_grecaptcha');
  }

  async function navigateToMainPage() {
    // set user data to user context to provide to other components
    set(userAttributes);
    // get user permission and set to permission context to provide to other components
    
    const userPermissionInArray = await getUserPermission(
      (userAttributes?.['custom:role'] ?? '') + '',
      (userAttributes?.['custom:company'] ?? '') + '');
    setPermission(userPermissionInArray);
    props.handleClose();
    sessionStorage.setItem('accessToken', accessToken.current);

    startTimeout(12 * 60);
    
    navigate("/dashboard");
    removeRecaptchaFromLS();
  }

  const setChallengeData = (data: any) => {
    sessionKey.current = data.Session ?? '';
    challengeName.current = data.ChallengeName ?? '';
    setChallengeObj({
      sessionKey: data.Session ?? '',
      username: username,
      challengeName: data.ChallengeName ?? ''
    });
  };

  const setLoginData = (data: InitiateAuthModel) => {
    accessToken.current = data.AuthenticationResult?.AccessToken ?? '';
  }

  async function getUserData() {
    try {
      const userData = await getUserCognitoAdmin(username);
      if (userData.status === 200) {
        let sessionData: Array<{ Name: string, Value: string | number }> = userData.data?.UserAttributes ?? [];
        // userAttributes = userData.data.UserAttributes.map( (user: { Name: string, Value: string | number }) => ({[user.Name]: user.Value}));
        userAttributes = userData.data.UserAttributes.reduce(
          (acc: {[key: string]: string | number}, cur: UserAttributes) => 
          ({...acc, ...{[cur.Name]: cur.Value}}), {});
        userAttributes['Enabled'] = userData.data.Enabled;
        userAttributes['UserCreateDate'] = userData.data.UserCreateDate;
        userAttributes['UserLastModifiedDate'] = userData.data.UserLastModifiedDate;
        userAttributes['UserStatus'] = userData.data.UserStatus;
        userAttributes['Username'] = userData.data.Username;
        if (userData.data.PreferredMfaSetting) {
          userAttributes['PreferredMfaSetting'] = userData.data.PreferredMfaSetting;
          sessionData = [...sessionData, {Name: 'PreferredMfaSetting', Value: userData.data.PreferredMfaSetting}];
        }
        setSessionData(sessionData);
        return userData;
      }
    } catch (error: any) {
      setToastState({ ...toastState, openCloseState: true, duration: 5000, message: UtilService.generateErrorToastMessage(error), type: 'error' });
    }
  }

  function checkIfUserRoleRequireMfaSetup() {
    if (userAttributes) {
      // const requireMfaSetup = userAttributes?.['custom:role']?.toString().toLowerCase() === 'admin' &&
      //   !userAttributes?.['PreferredMfaSetting'];
      // if (requireMfaSetup) {
      //   return true;
      // } else {
      //   return false;
      // }
      const requireMfaSetup = (
        userAttributes?.['email']?.toString().toLowerCase() !== 'eugenetan.stea@gmail.com' &&
        userAttributes?.['email']?.toString().toLowerCase() !== 'staeroflm@gmail.com' &&
        !userAttributes?.['PreferredMfaSetting']
      );
      if (requireMfaSetup) {
        return true;
      } else {
        return false;
      }
    }
  }

  async function mfaFlow(obj: { [key: string]: string }) {
    const associateMfaData = await associateSoftwareTokenCognito(obj);
    if (associateMfaData.status === 200) {
      const qrCode = `otpauth://totp/${username}?secret=${associateMfaData.data.SecretCode}&issuer=FLM`
      setSecretCode(qrCode);
      setValidatorOpen(true);
    }
  }

  function setSessionData(obj: any) {
    obj.forEach((x: any) => {
      sessionStorage.setItem(x.Name, x.Value);
    });
  }

  // re-adjust the flow to bypass the recaptcha
  // async function logIn(e: React.FormEvent<HTMLFormElement>) {
  //   e.preventDefault();
  //   seterrorMsg("");
  //   try {
  //     const authData = await initiateAuthCognito(username, password);
  //     if (authData.status === 200) {
  //       setLoginData(authData.data);
  //       setChallengeData(authData.data);
  //       await getUserData();
  //       if (challengeName.current) {
  //         onAuthChallenge(challengeName.current);
  //       } else {
  //         if (localStorage.getItem("_grecaptcha") != null) {
  //           navigateToMainPage();
  //         } else {
  //           const mfaRequired = checkIfUserRoleRequireMfaSetup();
  //           if (mfaRequired) {
  //             mfaFlow({ AccessToken: accessToken.current });
  //           } else {
  //             seterrorMsg("*reCaptcha is required");
  //           }
  //         }
  //       }
  //     }
  //   } catch (error: any) {
  //     setToastState({
  //       ...toastState,
  //       openCloseState: true,
  //       duration: 5000,
  //       message: UtilService.generateErrorToastMessage(error),
  //       type: "error",
  //     });
  //   }
  // }

  async function logIn(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    seterrorMsg("");
    try {
      const userData = await getUserCognitoAdmin(username);
      if ((userData.status === 200) &&
          (userData.data.Enabled === false)) {
            setToastState({ ...toastState, openCloseState: true, duration: 5000, message: `Account disabled. Kindly contact the Administrator for assistance.`, type: 'error' });
            return;
      }
      const authData = await initiateAuthCognito(username, password);
      if (authData.status === 200) {
        setLoginData(authData.data);
        setChallengeData(authData.data);
        await getUserData();
        if (challengeName.current) {
          onAuthChallenge(challengeName.current);
        } else {
          const mfaRequired = checkIfUserRoleRequireMfaSetup();
          if (mfaRequired) {
            mfaFlow({ AccessToken: accessToken.current });
          }
          else {
            navigateToMainPage();
          }
        }
      }
    } catch (error: any) {
      setToastState({ ...toastState, openCloseState: true, duration: 5000, message: UtilService.generateErrorToastMessage(error), type: 'error' });
    }
  }

  // async function logIn(e: React.FormEvent<HTMLFormElement>) {
  //   e.preventDefault();
  //   if (localStorage.getItem('_grecaptcha') != null) {
  //     seterrorMsg('');
  //     try {
  //       const authData = await initiateAuthCognito(username, password);
  //       if (authData.status === 200) {
  //         setLoginData(authData.data);
  //         setChallengeData(authData.data);
  //         await getUserData();
  //         if (challengeName.current) {
  //           onAuthChallenge(challengeName.current);
  //         } else {
  //           navigateToMainPage();
  //         }
  //       }
  //     } catch (error: any) {
  //       setToastState({ ...toastState, openCloseState: true, duration: 5000, message: UtilService.generateErrorToastMessage(error), type: 'error' });
  //     }
  //   }
  //   else {
  //     seterrorMsg('*reCaptcha is required');
  //   }
  // }

  const handleOnNewPasswordSubmit = async (event: any, password: NewPasswordType) => {
    event.preventDefault();
    setSubmitting(true);
    const payload = {
      ChallengeName: challengeName.current,
      ChallengeResponses: {
        NEW_PASSWORD: password.password,
        USERNAME: username
      },
      Session: sessionKey.current
    };
    if (await passwordErrorCheck(username, password)){
      setSubmitting(false);
      setNewPasswordOpen(false);    
      return;
    }
    try {
      const authChallengeData = await responseToAuthChallengeCognito(payload);
      if (authChallengeData.status === 200) {
        setNewPasswordOpen(false);
        setSubmitting(false);
        if (authChallengeData.data.ChallengeName) {
          setChallengeData(authChallengeData.data);
          onAuthChallenge(challengeName.current);
        } else {
          setLoginData(authChallengeData.data);
          const mfaRequired = checkIfUserRoleRequireMfaSetup();
          if (mfaRequired) {
            mfaFlow({ AccessToken: accessToken.current });
          } else {
            navigateToMainPage();
          }
        }
      }
    } catch (error: any) {
      setToastState({ ...toastState, openCloseState: true, duration: 5000, message: UtilService.generateErrorToastMessage(error), type: 'error' });
      setSubmitting(false);
      setNewPasswordOpen(false);
    }
  };

  let handleOnOtpSubmit = async (otp: string) => {
    if (challengeObj.challengeName === ConstantsEnum.MFA_TOKEN) {
      const payload = {
        ChallengeName: challengeObj.challengeName,
        ChallengeResponses: {
          SOFTWARE_TOKEN_MFA_CODE: otp,
          USERNAME: username
        },
        Session: challengeObj.sessionKey
      };
      try {
        const authChallengeData = await responseToAuthChallengeCognito(payload);
        if (authChallengeData.status === 200) {
          setLoginData(authChallengeData.data);
          setValidatorOpen(false);
          navigateToMainPage();
        }
      } catch(error) {
        setToastState(UtilService.errorToast(error, toastState));
      }
      
    } else {
      try {
        const verifyOtpData = await verifySoftwareTokenCognito(accessToken.current, otp);
        if (verifyOtpData.data?.Status.toLowerCase() === 'success') {
          setMfaCognito(accessToken.current);
        }
        setValidatorOpen(false);
        navigateToMainPage();
      } catch(error) {
        setToastState(UtilService.errorToast(error, toastState));
      }
     
    }

  }

  const handleOnForgetPasswordSubmit = async (event: any, uName: string, code: string, password: NewPasswordType) => {
    event.preventDefault();
    setSubmitting(true);
    const payload = { Username: uName, ConfirmationCode: code, Password: password.password };
    if (await passwordErrorCheck(uName, password)){
      setSubmitting(false);
      setForgetPasswordModalState(false);    
      return;
    }
    try {
      const confirmForgetPasswordRes = await confirmForgetPasswordCognito(payload);
      if (confirmForgetPasswordRes?.status === 200) {
        const SUCCESS_MSG = `Password has been reset`;
        setToastState({ ...toastState, openCloseState: true, duration: 5000, message: SUCCESS_MSG, type: 'success' });
        setForgetPasswordModalState(false);
      }
    } catch (error: any) {
      setToastState({ ...toastState, openCloseState: true, duration: 5000, message: UtilService.generateErrorToastMessage(error), type: 'error' });
      setForgetPasswordModalState(false);    
    }
    setSubmitting(false);
  };

  async function passwordErrorCheck(username: string, password: NewPasswordType){
    if(await passwordHistoryCheck(username, password.password)){    
      return true;
    };
    if(password.repeatPassword !== password.password){
      setToastState({ ...toastState, openCloseState: true, duration: 10000, message: `The re-entered password does not match the new password.`, type: 'error' });    
      return true;
    }
    if(!await passwordRequirementCheck(password.password)){
      setToastState({ ...toastState, openCloseState: true, duration: 10000, message: `Please fulfil the password requirements. Thank you.`, type: 'error' });   
      return true;
    }
    return false;
  }

  async function passwordHistoryCheck(username: string, password: string) {
    let passwordHistoryArray = [];
    let similarPassword = false;
    const passwordHistory = await getPasswordHistory(username);
    if (passwordHistory?.data?.result !== undefined) {
      passwordHistoryArray = passwordHistory.data.result.split(',');
      for (let i=0; i<passwordHistoryArray.length; i++) {
        if ((passwordHistoryArray[i] === password) &&
            (password !== 'dr0net@STEA')){
          setToastState({ ...toastState, openCloseState: true, duration: 10000, message: `Please use a different password. Thank you.`, type: 'error' });
          similarPassword = true;
        }
      }
    }
    return similarPassword;
  }

  async function passwordRequirementCheck(password: string) {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
    return passwordRegex.test(password);
  }

  const handleToastChanges = (state: boolean) => {
    setToastState({ ...toastState, openCloseState: state })
  };
  return (
    <div className="App">
      <Row>
        <Title id="modal-modal-title" level={2}>
          Login
        </Title>
      </Row>
      <Box component="form" id="loginForm" autoComplete='off' method="post" onSubmit={logIn}>
        <Stack spacing={2} sx={{ maxWidth: 600, margin: '0 auto', '& button': { m: 1 } }}>
          <TextField fullWidth id="outlined-basic" label="Username" variant="outlined" className='inputText' value={username} onChange={e => setUserName(e.target.value)} required />
          <FormControl fullWidth variant="outlined" className='inputText_pass' required>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={passwordShown ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordShown ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          {errorMsg && <div className="error"> {errorMsg}
          </div>}
          <Grid container>
            {/* <Grid item xs={8}>
              <ReCAPTCHA sitekey={recaptcha_site_key} onExpired={recaptchaExpired} onChange={getToken}/>
            </Grid> */}
            {/* <Grid item xs={4}>
              <Box sx={{backgroundColor:"#F5F5F5"}}> */}
              <Stack sx={{ maxWidth: 200, margin: '0 auto', display: 'inline-grid' }}>
                <Button size='small' sx={{ width: 90, height: 42, float: 'right' }} variant="contained" type='submit'>Login</Button>
                <Link href='#' onClick={() => setForgetPasswordModalState(true)}>Forget Password</Link>
              </Stack>
              {/* </Box>
            </Grid> */}
          </Grid>
          {/* <Stack flexDirection={'column'} alignContent={"center"}>
          <Button size='small' sx={{ maxWidth: 400, width:"95%", height: 42, float: 'right', alignContent:'center'}} variant="contained" type='submit'>Login</Button>
          <Link href='#' onClick={() => setForgetPasswordModalState(true)}>Forget Password</Link>
          </Stack> */}
        </Stack>
      </Box>
      <ValidatorComponent open={validatorOpen} secretCode={secretCode} handleModalClose={() => setValidatorOpen(false)} handleModalOnSubmit={handleOnOtpSubmit} />
      <PasswordModal open={newPasswordOpen} handleModalClose={() => setNewPasswordOpen(false)} handleModalOnSubmit={handleOnNewPasswordSubmit} />
      <ForgetPasswordModal open={forgetPasswordModalState} handleModalClose={() => setForgetPasswordModalState(false)} handleModalOnSubmit={handleOnForgetPasswordSubmit} />
      <BackdropComponent submitting={submitting} />
      {toastState.openCloseState ? <Toastbox openCloseState={true} message={toastState.message} type={toastState.type} duration={toastState.duration} callback={handleToastChanges} /> : <></>}
    </div>
  );
}

export default Login;

