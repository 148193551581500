// Models/Types
import { CognitoUsers, UserTableGenericType } from "../../_models/user";
import { TOASTBOX } from '../../_models/toastbox';
// THe rest
import { useEffect, useReducer, useState } from "react";
import { DataTable } from "../../_components/data-table";
import { TextService } from "../../_services/text.service";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Stack from "@mui/material/Stack";
import { UtilService } from "../../_services/utils.service";
import { changeUserPasswordCognitoAdmin,
         getListUserCognito,
         disableMfaByUsername,
         enableUserCognitoAdmin, 
         getUserCognitoAdmin, } from "../../_services/user.service";
import Toastbox from "../../_components/toastbox";
import { format, parseJSON } from 'date-fns';
import { Dropdown, MenuProps, message } from "antd";
import { createPortal, render } from "react-dom";
import { Box, Button, FormControl, Grid, Modal, Typography } from "@mui/material";
import { MenuInfo } from "rc-menu/lib/interface";

const tableSortDirection = ['descend', 'ascend', 'descend'];

const items = [
    {key: 'reset', label: 'Reset Password',},
    {key: 'mfa', label: 'Reset MFA'},
    {key :'enableAccount', label: 'Enable Account'}
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

/**
 * A modal that contains password form
 * @param param0 open - show/hide the modal, handleModalClose - callback fn to close the modal, handleModalOnSubmit - callback fn to handle submit flow.
 * @returns 
 */
const ResetPasswordModal = ({ userInfo, handleModalClose, handleModalOnSubmit }: any) => {
    
    const handleOnSubmit = async (event: any) => {
        event.preventDefault();
        try {
            const res = await changeUserPasswordCognitoAdmin({Username: userInfo?.username});
            handleModalOnSubmit(res);
        }catch(err: any) {
            handleModalOnSubmit(err);
        }
        
    };

    return (
        <>
            <Modal
                open={true}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} component='form' onSubmit={handleOnSubmit}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Reset Password
                    </Typography>
                    <Typography id="modal-modal-title" variant="body1">
                        Are you sure you want to reset the password for this user?
                    </Typography>
                    <FormControl sx={{ width: '100%', margin: '0 auto', textAlign: 'center' }} >
                    <Grid container spacing={2} sx={{marginTop: 2}}>
                        <Grid item xs={12} display='flex' justifyContent='space-between'>
                            <Button variant='outlined' onClick={handleModalClose} sx={{ textAlign: 'left' }} type='reset'>
                                Cancel
                            </Button>
                            <Button variant='contained' className='float-right' type='submit'>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                    </FormControl>
                    
                </Box>
            </Modal>
        </>
    )
};

const ResetMFAModal = ({ userInfo, handleModalClose, handleModalOnSubmit }: any) => {
    const handleOnSubmit = async (event: any) => {
        event.preventDefault();
        try {
            const res = await disableMfaByUsername(userInfo.username); // take in selected username to disable MFA
            handleModalOnSubmit(res);
        } catch (err: any) {
            handleModalOnSubmit(err);
        }
    };

    return (
        <>
            <Modal
                open={true}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} component='form' onSubmit={handleOnSubmit}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Reset MFA
                    </Typography>
                    <Typography id="modal-modal-title" variant="body1">
                        Are you sure you want to reset the MFA for this user?
                    </Typography>
                    <FormControl sx={{ width: '100%', margin: '0 auto', textAlign: 'center' }} >
                    <Grid container spacing={2} sx={{marginTop: 2}}>
                        <Grid item xs={12} display='flex' justifyContent='space-between'>
                            <Button variant='outlined' onClick={handleModalClose} sx={{ textAlign: 'left' }} type='reset'>
                                Cancel
                            </Button>
                            <Button variant='contained' className='float-right' type='submit'>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                    </FormControl>
                    
                </Box>
            </Modal>
        </>
    )
};

const EnableAccountModal = ({ userInfo, handleModalClose, handleModalOnSubmit }: any) => {
    const handleOnSubmit = async (event: any) => {
        event.preventDefault();
        try {
            const res = await enableUserCognitoAdmin(userInfo.username); // take in selected username to enable account
            handleModalOnSubmit(res);
        } catch (err: any) {
            handleModalOnSubmit(err);
        }
    };

    return (
        <>
            <Modal
                open={true}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} component='form' onSubmit={handleOnSubmit}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Enable Account
                    </Typography>
                    <Typography id="modal-modal-title" variant="body1">
                        Are you sure you want to enable account for this user?
                    </Typography>
                    <FormControl sx={{ width: '100%', margin: '0 auto', textAlign: 'center' }} >
                    <Grid container spacing={2} sx={{marginTop: 2}}>
                        <Grid item xs={12} display='flex' justifyContent='space-between'>
                            <Button variant='outlined' onClick={handleModalClose} sx={{ textAlign: 'left' }} type='reset'>
                                Cancel
                            </Button>
                            <Button variant='contained' className='float-right' type='submit'>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                    </FormControl>
                    
                </Box>
            </Modal>
        </>
    )
};




const UserTable = (passResult: any) => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [users, setUsers] = useState<UserTableGenericType[]>([]);
    const [userInfo, setUserInfo] = useState<UserTableGenericType>({});
    const [toastState, setToastState] = useState<TOASTBOX>({ openCloseState: false, message: '', type: 'success', duration: 5000, callback: () => false });
    const [resetPasswordModalState, setResetPasswordModalState] = useState(false);
    const [triggerEffect, setTriggerEffect] = useState(false);
    const handleToastChanges = (state: boolean) => {
        setToastState({ ...toastState, openCloseState: state })
    };
    const [resetMFAModalState, setResetMFAModalState] = useState(false);
    const [enableAccountModalState, setEnableAccountModalState] = useState(false);
    const [selectedAction, setSelectedAction] = useState('');


    const userTableColumns = [
        
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            defaultSortOrder: 'descend',
            sortDirections: tableSortDirection,
            sorter: (a: any, b: any) => UtilService.Sorter.textSort(a.username, b.username),
            render: TextService.handleTextData
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            defaultSortOrder: 'descend',
            sortDirections: tableSortDirection,
            sorter: (a: any, b: any) => UtilService.Sorter.textSort(a.role, b.role),
            render: (text: string) => TextService.handleTextData(UtilService.titleCase(text))
        },
        {
            title: 'Status',
            dataIndex: 'userStatus',
            key: 'status',
            defaultSortOrder: 'descend',
            sortDirections: tableSortDirection,
            sorter: (a: any, b: any) => UtilService.Sorter.textSort(a.status, b.status),
            render: (text: string) => TextService.handleTextData(UtilService.titleCase(text))
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            defaultSortOrder: 'descend',
            sortDirections: tableSortDirection,
            sorter: (a: any, b: any) => UtilService.Sorter.textSort(String(a.enabled), String(b.enabled)),
            render: (text: boolean) => TextService.handleTextData(text ? 'True' : 'False')
        },
        {
            title: 'Last Login',
            dataIndex: 'lastLogin',
            key: 'lastLogin',
            defaultSortOrder: 'descend',
            sortDirections: tableSortDirection,
            width: '20%',
            sorter: (a: any, b: any) => UtilService.Sorter.textSort(a.lastLogin, b.lastLogin),
            render: (text: string) => TextService.handleTextData(text ? format(parseJSON(new Date(text)), 'dd/MM/yyyy HH:mm:ss') : '-')
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            width: '5%',
            render: (item: any, record: any, index: number) => {
                // Filter the items to exclude 'Enable Account' if 'enabled' is false
                
                const filteredItems = !record.enabled ? items : items.filter(item => item.key !== 'enableAccount');
                return (
                    <Stack direction="row">
                        <Dropdown menu={{ items: filteredItems, onClick: (e) => handleActionMenuClick(record, e) }}>
                            <IconButton aria-label="more" color='primary'>
                                <MoreVertIcon />
                            </IconButton>
                        </Dropdown>
                    </Stack>
                );
            }
        }
    ];

    useEffect(function onFirstLoad() {
        
        const getUserData = async () => {
            try {
                
                setIsFetching(true);

                const cognitoUserData = await getListUserCognito();
                //console.log(cognitoUserData.data);
                const userData = cognitoUserData.data.reduce((result: Array<{ [key: string]: string | number | boolean }>, _data: CognitoUsers) => {
                    let user: { [key: string]: string | number | boolean } = {};
                    if (_data) {
                        _data.Attributes.forEach(attr => user[attr.Name.slice(attr.Name.indexOf(':') !== -1 ? attr.Name.indexOf(':') + 1 : 0)] = attr.Value);
                        user['enabled'] = _data.Enabled;
                        user['userCreateDate'] = _data.UserCreateDate;
                        user['userLastModifiedDate'] = _data.UserLastModifiedDate;
                        user['userStatus'] = _data.UserStatus;
                        user['username'] = _data.Username;
                    }
                    
                    result.push(user);
                    return result;
                }, []);
                const userList = UtilService.addRowKeyToTableItems(userData) ?? [];
                setUsers(userList);
                setIsFetching(false);
            } catch (error) {
                console.error(error);
                return (<><Toastbox openCloseState={true} message={toastState.message} type={toastState.type} duration={toastState.duration} callback={handleToastChanges} /></>)
            }
        };
        
        getUserData();
        setTriggerEffect(false);
    }, [triggerEffect]);

    const handleActionMenuClick= (record: UserTableGenericType, e: MenuInfo) => {
        setUserInfo(record);
        setSelectedAction(e.key); // Store the selected action
        if (e.key === 'reset') {
            setResetPasswordModalState(true);
        } else if (e.key === 'mfa') {
            setResetMFAModalState(true);
        } else if (e.key ==='enableAccount'){
            setEnableAccountModalState(true);
        }
    };

    const handleOnResetMFA = (result: any) => {
        if (result?.status === 200) {
            setToastState({ ...toastState, openCloseState: true, message: 'MFA has been reset for this user.' });
            setResetMFAModalState(false);
            setTriggerEffect(true);
        } else {
            setToastState({
                ...toastState,
                openCloseState: true,
                duration: 5000,
                message: `${UtilService.generateErrorToastMessage(result)}. Kindly approach Tech Support with this error message.`,
                type: 'error'
            });
        }
    };

    const handleOnEnableAccount = (result: any) => {
        if (result?.status === 200) {
            setToastState({ ...toastState, openCloseState: true, message: 'Account has been enabled for this user.' });
            setEnableAccountModalState(false);
            setTriggerEffect(true);
        } else {
            setToastState({
                ...toastState,
                openCloseState: true,
                duration: 5000,
                message: `${UtilService.generateErrorToastMessage(result)}. Kindly approach Tech Support with this error message.`,
                type: 'error'
            });
        }
    };

    const handleOnResetPassword = (result: any) => {
        if (result?.status === 200) {
            setToastState({ ...toastState, openCloseState: true, message: 'Password has been reset to the default password for this user.'});
            setResetPasswordModalState(false);
            setTriggerEffect(true);
        } else {
            setToastState({ ...toastState, openCloseState: true, duration: 5000, message: `${UtilService.generateErrorToastMessage(result)}. Kindly approach Tech Support with this error message.`, type: 'error' });
        }
    }

    return (
        <>
            <DataTable columns={userTableColumns} data={users} loading={isFetching}></DataTable>
            
            {resetPasswordModalState && <ResetPasswordModal userInfo={userInfo} handleModalClose={() => setResetPasswordModalState(false)} handleModalOnSubmit={handleOnResetPassword} />}
            {toastState.openCloseState && <Toastbox openCloseState={true} message={toastState.message} type={toastState.type} duration={toastState.duration} callback={(state: boolean) => setToastState({ ...toastState, openCloseState: state })} />}
            {resetMFAModalState && <ResetMFAModal userInfo={userInfo} handleModalClose={() => setResetMFAModalState(false)} handleModalOnSubmit={handleOnResetMFA}/>}
            {enableAccountModalState && <EnableAccountModal userInfo={userInfo} handleModalClose={() => setEnableAccountModalState(false)} handleModalOnSubmit = {handleOnEnableAccount} />}
        </>

    );
}

export default UserTable;