import React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { TOASTBOX } from '../_models/toastbox';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toastbox = ({openCloseState, message, type, duration = 2000, callback}: TOASTBOX) => {
  const [open, setOpen] = React.useState(openCloseState);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    if (callback) callback(false);
  };

  return (
    <Stack spacing={2} sx={{ minWidth: '30%'}}>
      <Snackbar sx={{zIndex: 10000}} open={open} autoHideDuration={duration} onClose={handleClose} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%', textAlign: 'center'}}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default Toastbox;