import axios from "axios";
import { environment } from "../environment";
import { ROLE_MODEL } from "../_models/user";
import { getUserProject } from "./permission.service";
// import { DropdownSelectKeyValue } from "../_models/dropdown";

export namespace ConstantService {
    const apiUrl = environment.apiurl;
    const apiEndpoint = '/constant';
    
    export async function getActivity() {
        const result = await axios.get(`${apiUrl}${apiEndpoint}/activity`);
        return result?.data.map( (activity: {activityId: string, activity: string, project: string[]}) => ({value: activity.activity, project: activity.project}) ?? []);
    }
    export async function getPilot() {
        const result = await axios.get(`${apiUrl}${apiEndpoint}/pilot`);
        return result?.data.reduce((res: {[key:string]: string[]}, pilot: {pilotId: number, pilot: string, external: boolean}) => {
            if (!pilot.external) res['pilot'] = [...res?.['pilot'] ?? [], pilot.pilot];
            else res['externalPilot'] = [...res?.['externalPilot'] ?? [], pilot.pilot];
            return res;
        }, []);
    }
    export async function getVenue() {
        const result = await axios.get(`${apiUrl}${apiEndpoint}/venue`);
        return result?.data.map( (venue: {venueId: string, venue: string, project: string[]}) => ({value: venue.venue, project: venue.project}) ?? []);
    }

    export async function getRole(company: string) {
        const result = await axios.get(`${apiUrl}${apiEndpoint}/role`, { params: { company } });
        return result?.data ?? [];
    }

    export async function getFlightDisplay() {
        const result = await axios.get(`${apiUrl}${apiEndpoint}/flightDisplay`);
        return result
    }
}

export enum ConstantsEnum {
    ALL_VENUE = 'All Venue',
    MFA_TOKEN = 'SOFTWARE_TOKEN_MFA',
    NEW_PASSWORD = 'NEW_PASSWORD_REQUIRED',
    USER_PASS_FLOW = 'USER_PASSWORD_AUTH',
    REFRESH_TOKEN_FLOW = 'REFRESH_TOKEN_AUTH'
};

export const JIRA_CUSTOMFIELDS = {
    DNOL: {
        UAV_TAIL_NO: `13141`,
        ROTOR_ARMS_SN: `13116`,
        BATTERY_SN: `13112`,
        ADD_BATTERIES: `13104`,
        PAYLOAD_ID: `13101`,
        CARGO_WEIGHT: `16612`,
        DROHUB_ID: `13119`,
        DROPORT_ID: `13117`,
        OPERATOR: `13602`,
        EXT_OPERATOR: `13132`,
        TAKE_OFF_TIME: `13121`,
        LANDING_TIME: `13122`,
        FLIGHT_DURATION: `13135`,
        ACTIVITY: `13603`,
        VENUE: `13129`,
        PROJECT: `10656`,
        AIRCRAFT_TYPE: `13379`,
        FCC_VERSION: `13380`,
        GCS_VERSION: `13381`,
        DROPORT_VERSION: `16001`,
        CFMS_FLIGHT_ID: `13384`,
        COVERAGE_AREA: `13382`,
        FLIGHT_DATE: `13383`,
        AMEND_COUNT: `14300`
    },
    DNAM: {
        ASSET_TYPE: `13139`,
        SERIAL_NO: `10168`,
    },
    GENERAL : {
        SOFTWARE_CONFIG: `13140`,
    },
};

// For Dev
export const JIRA_CUSTOMFIELDS_DEV = {
    DNOL: {
        UAV_TAIL_NO: `10031`,
        ROTOR_ARMS_SN: `10044`,
        BATTERY_SN: `10043`,
        ADD_BATTERIES: `10006`,
        PAYLOAD_ID: `10008`,
        CARGO_WEIGHT: `10021`, // temporarily using FCC_VERSION customfield
        DROHUB_ID: `10013`,
        DROPORT_ID: `10049`,
        OPERATOR: `10034`,
        EXT_OPERATOR: `10014`,
        TAKE_OFF_TIME: `10010`,
        LANDING_TIME: `10015`,
        FLIGHT_DURATION: `10059`,
        ACTIVITY: `10047`,
        VENUE: `10057`,
        PROJECT: `10028`,
        AIRCRAFT_TYPE: `10068`,
        FCC_VERSION: `10021`,
        GCS_VERSION: `10053`,
        DROPORT_VERSION: `10021`, // temporarily using FCC_VERSION customfield
        CFMS_FLIGHT_ID: `10063`,
        COVERAGE_AREA: `10052`,
        FLIGHT_DATE: `10019`,
        AMEND_COUNT: `10021` // temporarily using FCC_VERSION customfield 
    },
    DNAM: {
        ASSET_TYPE: `10030`,
        SERIAL_NO: `10024`,
    },
    GENERAL : {
        SOFTWARE_CONFIG: `10069`,
    },
};

export const JIRA_CUSTOMFIELDS_DEVELOPMENT = {
    DNOL: {
        UAV_TAIL_NO: `13220`,
        ROTOR_ARMS_SN: `13208`,
        BATTERY_SN: `13230`,
        ADD_BATTERIES: `13202`,
        PAYLOAD_ID: `13204`,
        DROHUB_ID: `13209`,
        DROPORT_ID: `13235`,
        OPERATOR: `13223`,
        EXT_OPERATOR: `13210`,
        TAKE_OFF_TIME: `13206`,
        LANDING_TIME: `13211`,
        FLIGHT_DURATION: `13244`,
        ACTIVITY: `13233`,
        VENUE: `13242`,
        PROJECT: `10656`,
        // AIRCRAFT_TYPE: `13379`,
        FCC_VERSION: `13217`,
        GCS_VERSION: `13239`,
        CFMS_FLIGHT_ID: `13248`,
        COVERAGE_AREA: `13238`,
        FLIGHT_DATE: `13215`
    },
    DNAM: {
        ASSET_TYPE: `13219`,
        SERIAL_NO: `10168`,
    },
    GENERAL : {
        SOFTWARE_CONFIG: `13251`,
    },
};

export const MODULE_ID = {
    MODULE_LEVEL: {
        DASHBOARD: 'T_01',
        FLIGHT_LOGS: 'T_02',
        MAINTENANCE: 'T_03',
        SQN: 'T_04',
        DOCUMENTATION: 'T_05',
    },
    FUNCTION_LEVEL: {
        PROJECTS: 'F_01',
        ADD_FLIGHT_LOGS: 'FLM_001',
        EDIT_FLIGHT_LOGS: 'FLM_002',
        DELETE_FLIGHT_LOGS: 'FLM_003',
        ADMIN_CONSOLE: 'FLM_004',
        VIEW_FLIGHT_LOGS: 'FLM_005',
        VIEW_FLIGHT_LOGS_SKYPORTS: 'FLM_006',
        UPLOAD_DOCUMENT: 'FLM_007',
    }
};