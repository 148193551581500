import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import { PermissionProvider } from './_providers/PermissionProvider';
import { UserProvider } from './_providers/UserProvider';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement as Element);

root.render(
    <BrowserRouter>
        <UserProvider>
            <PermissionProvider>
                <App />
            </PermissionProvider>
        </UserProvider>
    </BrowserRouter>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
