import { Link } from "react-router-dom";

const LinkComponent = (props: { id: string, to: string, text?: string, image?: { src: string } }) => {
    return (
        <>
            <Link key={props.id} to={props.to}>
                {props.image ?
                    <>
                        <img className="logo" src={props.image.src} />
                    </> : props.text}
            </Link>
        </>
    )
}

export default LinkComponent;