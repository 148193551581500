import { Space } from "antd";
import './dashboard.css';

const Dashboard = () => {
    return (
        <div className="dashboard-container">
            <iframe width="100%" 
            height="100%" 
            src="https://lookerstudio.google.com/embed/reporting/e2b82d7a-5dcd-47aa-b0e3-8a10fae9d930/page/p_chb5r7ll6c"
            allowFullScreen></iframe>
        </div>
    );
}

export default Dashboard;