import axios from "axios";
import CryptoJS from "crypto-js";
import { environment } from "../environment";
import { CognitoApiGeneric, CognitoListUsersAPI, User, UserTableGenericType } from "../_models/user";
import { CognitoErrorModel, InitiateAuthModel } from "../_models/cognito";
import { ConstantsEnum } from "./constant.service";

const apiUrl = environment.apiurl;
const secretKey = environment.secretKey;
let timeoutId: any;

export async function startTimeout(remainingTimeInMinutes : number){
    timeoutId = setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }, remainingTimeInMinutes * 60 * 1000);
}

export async function stopTimeout(){
    clearTimeout(timeoutId);
};

export async function login(username:string, password:string){
    const tabID = sessionStorage.tabID;
    const hashPassword = CryptoJS.AES.encrypt(password,secretKey).toString(); 
    return axios.post(`${apiUrl}/user/authenticate`, {username: username,password: hashPassword,tabID: tabID})
    .then((user=>{
        sessionStorage.setItem('user',JSON.stringify(user.data));
        return user;
    }));
}

export async function logout(){
    sessionStorage.removeItem('user');
    localStorage.removeItem('moduleID');
    return true;
}

export function registerUser(user: User) {
    return axios.post(`${apiUrl}/user/register`, user);
}

// Cognito Registration API //
export async function registerCognito(user: User) {
    return axios.post(`${apiUrl}/user/registerCognito`, user);
}

export async function createUserCognito(user: User) {
    return axios.post(`${apiUrl}/user/createUserCognito`, user);
}

export async function initiateAuthCognito(username: string, password: string) {
    const hashPassword = CryptoJS.AES.encrypt(password, secretKey).toString(); 
    return axios.post<InitiateAuthModel & CognitoErrorModel>(`${apiUrl}/user/initiateAuthCognito`, {Username: username, Password: hashPassword, AuthFlow: ConstantsEnum.USER_PASS_FLOW});
}

export async function getUserCognito(accessToken: string) {
    return axios.get(`${apiUrl}/user/getUserCognito`, { params: { accessToken } });
}

export async function getUserCognitoAdmin(username: string | number | boolean) {
    return axios.get(`${apiUrl}/user/getUserCognitoAdmin`, { params: { username } });
}

export async function associateSoftwareTokenCognito(obj: CognitoApiGeneric) {
    return axios.post(`${apiUrl}/user/associateSoftwareTokenCognito`, obj);
}

export async function verifySoftwareTokenCognito(accessToken: string, otp: string) {
    return axios.post(`${apiUrl}/user/verifySoftwareTokenCognito`, { accessToken, otp });
}

export async function setMfaCognito(accessToken: string) {
    return axios.post(`${apiUrl}/user/setMfaCognito`, { accessToken });
}

export async function disableMfaByUsername(username: string) {
    return axios.post(`${apiUrl}/user/disableMfaByUsername`,  { username }  );
}

export async function responseToAuthChallengeCognito(payload: CognitoApiGeneric) {
    return axios.post<InitiateAuthModel & CognitoErrorModel>(`${apiUrl}/user/responseToAuthChallengeCognito`, payload);
}

export async function getListUserCognito() {
    return axios.get<CognitoListUsersAPI[]>(`${apiUrl}/user/getListUserCognito`);
}

export async function resetUserPasswordCognitoAdmin(obj: CognitoApiGeneric) {
    return axios.post(`${apiUrl}/user/resetUserPasswordCognitoAdmin`, obj);
}

export async function changeUserPasswordCognitoAdmin(obj: CognitoApiGeneric) {
    return axios.post(`${apiUrl}/user/changeUserPasswordCognitoAdmin`, obj);
}

export async function forgetPasswordCognito(obj: CognitoApiGeneric) {
    return axios.post(`${apiUrl}/user/forgetPasswordCognito`, obj);
}

export async function confirmForgetPasswordCognito(obj: CognitoApiGeneric) {
    return axios.post(`${apiUrl}/user/confirmForgetPasswordCognito`, obj);
}

export async function getPasswordHistory(username: string) {
    return axios.get(`${apiUrl}/user/getPasswordHistory`, { params: { username } }  );
}

export async function enableUserCognitoAdmin(username: any) {
    return axios.post(`${apiUrl}/user/enableUserCognitoAdmin`,  {username}  );
}

