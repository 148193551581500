import Backdrop from '@mui/material/Backdrop/Backdrop';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

export const BackdropComponent = ({ submitting }: { submitting: boolean }) =>
    (<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 12000 }}
        open={submitting}
    >
        <CircularProgress color="inherit" />
    </Backdrop>)
