import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from "@mui/material/Typography";


export namespace TextService {
    export function handleTextData(text: string | string[]) {
        return Array.isArray(text)  && text.length > 1 ? text.map(t => <List key={t} sx={{ listStyleType: 'square', p: 0 }}>
            <ListItem key={t} sx={{ display: 'list-item' }}>
                <Typography key={t} variant='body1'>
                    {t ? t : '-'}
                </Typography>
            </ListItem>
        </List>)
            :
            <Typography variant='body1'>
                {text && text !== '0s' ? text : '-'}
            </Typography>
    }
}
