export const environment = {
    reCaptchaSiteKey:"6LevdrYjAAAAADxQEaXTFTiZNCPcLPiJOjfcIGvI",
    // apiurl:"https://119.73.229.78:12202",
    apiurl: getApiUrl(),
    secretKey:"UBU"
}

function getApiUrl() {
    const uriConstantsObj: {[key: string]: {ip: string, port: string}} = {
        production: { ip: '119.73.229.78', port: '12202'},
        development: { ip: '119.73.229.78', port: '12302'},
        local: { ip: 'localhost', port: '12302'},
    };
    const reactAppEnv = process.env.REACT_APP_ENV ?? 'production';
    const uriObj: {ip: string, port: string} = uriConstantsObj[reactAppEnv];
    //const uri = `https://${uriObj.ip}:${uriObj.port}`; // Use in FLM Server
    const uri = `https://${process.env.NODE_ENV === 'production' ? '119.73.229.78:12202' : 'localhost:12302'}`; // Use in Local Server
    console.log('apiurl: ', uri, process.env.NODE_ENV);
    return uri;
}